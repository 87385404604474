<template>
  <div>
    <div class="flex items-center">
      <h1 class="flex-grow pb-0">Mouvements</h1>
      <router-link
        tag="button"
        class="mf-secondary-button"
        :to="{name: 'restitution'}">
        Restituer du matériel
      </router-link>
      <router-link
        tag="button"
        class="mf-primary-button ml-3"
        :to="{name: 'delivery'}">
        Distribuer du matériel
      </router-link>
    </div>
    <article-operation-groups />
  </div>
</template>

<script>
import ArticleOperationGroups from '@components/ArticleOperationGroups'

export default {
  components: {
    ArticleOperationGroups
  }
}
</script>
