<template>
  <div>
    <table class="table-fixed w-full mb-3" v-if="filteredPhones && filteredPhones.length">
      <thead>
        <tr>
          <th>Numéro</th>
          <th>Type</th>
          <th class="w-1/2">Salarié</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="phone in filteredPhones" :key="phone.id"
          class="odd:bg-gray-100 hover:bg-indigo-100"
          :class="{
            'cursor-pointer': !isNested,
            'mf-highlighted': $route.params.phoneId === phone.id,
            'mf-lost': phone.isLost
          }"
          @click="$router.push({name: 'phone', params: {id: phone.id}})">
          <td class="mf-tnum border px-4 py-1 whitespace-no-wrap">
            {{phone.identifier}}
          </td>
          <td class="border px-4 py-1 whitespace-no-wrap">
            {{phone.subscription}}
          </td>
          <td class="border px-4 py-1 whitespace-no-wrap">
            {{phone.employee ? phone.employee.fullName : '…'}}
          </td>
        </tr>
      </tbody>
    </table>
    <p class="text-gray-600" v-else>
      Aucun téléphone.
    </p>
  </div>
</template>

<script>
export default {
  props: {
    isNested: Boolean,
    phones: Array
  },
  computed: {
    filteredPhones() {
      return this.phones
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
