<template>
  <div>
    <table class="table-auto w-full mb-3" v-if="operationGroups && operationGroups.length">
      <thead>
        <tr>
          <th>Date</th>
          <th>Mouvement</th>
          <th v-if="!isNested">Salarié</th>
          <th>Site</th>
          <th v-if="!isNested"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="operationGroup in operationGroups" :key="operationGroup.id"
          class="odd:bg-gray-100 hover:bg-indigo-100"
          :class="{'cursor-pointer': !isNested, 'mf-highlighted': $route.params.operationGroupId === operationGroup.id }"
          @click="operationGroup.employee && $router.push({name: 'employee', params: {id: operationGroup.employee.id, operationGroupId: operationGroup.id}})">
          <td class="border px-4 py-1 whitespace-no-wrap">
            {{formatDate(operationGroup.operationDate)}}
          </td>
          <td class="border px-4 py-1 w-2/3">
            <div class="flex items-center">
              <div class="mr-3 mt-1 flex-shrink-0">
                <svg v-if="operationGroup.kind === 'delivery'" width="26" height="26" version="1.1" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g transform="scale(1.08333)">
                    <path fill="none" stroke="#ED64A6" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M21.4926,13.0074c2.34315,2.34315 2.34315,6.14214 0,8.48528c-2.34315,2.34315 -6.14214,2.34315 -8.48528,0c-2.34315,-2.34315 -2.34315,-6.14214 -1.77636e-15,-8.48528c2.34315,-2.34315 6.14214,-2.34315 8.48528,-1.77636e-15"></path>
                    <path fill="none" stroke="#ED64A6" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.25,17.25h6"></path>
                    <path fill="none" stroke="#185990" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.25,18.75h-6h-6.55671e-08c-0.828427,-3.62117e-08 -1.5,-0.671573 -1.5,-1.5c0,0 0,0 0,0v-10.5l1.77636e-14,2.26494e-07c-1.25089e-07,-0.828427 0.671573,-1.5 1.5,-1.5h15l-6.55671e-08,1.77636e-15c0.828427,-3.62117e-08 1.5,0.671573 1.5,1.5v1.5"></path>
                    <path fill="none" stroke="#185990" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18.266,5.647l-2.821,-4.229l1.23093e-08,1.84657e-08c-0.277582,-0.416412 -0.744551,-0.666962 -1.245,-0.668h-8.9l8.98727e-09,-6.52811e-13c-0.501513,3.6431e-05 -0.969828,0.250705 -1.248,0.668l-2.818,4.229"></path>
                    <path fill="none" stroke="#185990" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.75,0.75v4.5"></path>
                  </g>
                </svg>
                <svg v-if="operationGroup.kind === 'restitution'" width="26" height="26" version="1.1" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g transform="scale(1.08333)">
                    <path fill="none" stroke="#48BB78" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M21.4926,13.0074c2.34315,2.34315 2.34315,6.14214 0,8.48528c-2.34315,2.34315 -6.14214,2.34315 -8.48528,0c-2.34315,-2.34315 -2.34315,-6.14214 -1.77636e-15,-8.48528c2.34315,-2.34315 6.14214,-2.34315 8.48528,-1.77636e-15"></path>
                    <path fill="none" stroke="#48BB78" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.25,14.25v6"></path>
                    <path fill="none" stroke="#48BB78" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M14.25,17.25h6"></path>
                    <path fill="none" stroke="#185990" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.25,18.75h-6h-6.55671e-08c-0.828427,-3.62117e-08 -1.5,-0.671573 -1.5,-1.5c0,0 0,0 0,0v-10.5l1.77636e-14,2.26494e-07c-1.25089e-07,-0.828427 0.671573,-1.5 1.5,-1.5h15l-6.55671e-08,1.77636e-15c0.828427,-3.62117e-08 1.5,0.671573 1.5,1.5v1.5"></path>
                    <path fill="none" stroke="#185990" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18.266,5.647l-2.821,-4.229l1.23093e-08,1.84657e-08c-0.277582,-0.416412 -0.744551,-0.666962 -1.245,-0.668h-8.9l8.98727e-09,-6.52811e-13c-0.501513,3.6431e-05 -0.969828,0.250705 -1.248,0.668l-2.818,4.229"></path>
                    <path fill="none" stroke="#185990" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.75,0.75v4.5"></path>
                  </g>
                </svg>
                <svg v-if="operationGroup.kind === 'lost'" width="26" height="26" version="1.1" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g transform="scale(1.08333)">
                    <path fill="none" stroke="#ED8936" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M21.4926,13.0074c2.34315,2.34315 2.34315,6.14214 0,8.48528c-2.34315,2.34315 -6.14214,2.34315 -8.48528,0c-2.34315,-2.34315 -2.34315,-6.14214 -1.77636e-15,-8.48528c2.34315,-2.34315 6.14214,-2.34315 8.48528,-1.77636e-15"></path>
                    <path fill="none" stroke="#ED8936" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.375,16.125l2.13163e-14,2.83117e-07c-1.56361e-07,-1.03553 0.839466,-1.875 1.875,-1.875c1.03553,-1.56361e-07 1.875,0.839466 1.875,1.875c1.56361e-07,1.03553 -0.839466,1.875 -1.875,1.875"></path>
                    <path fill="none" stroke="#ED8936" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.25,20.25h-1.63918e-08c0.207107,-9.05293e-09 0.375,0.167893 0.375,0.375c9.05293e-09,0.207107 -0.167893,0.375 -0.375,0.375c-0.207107,9.05293e-09 -0.375,-0.167893 -0.375,-0.375l3.55271e-15,5.66234e-08c-3.12723e-08,-0.207107 0.167893,-0.375 0.375,-0.375"></path>
                    <path fill="none" stroke="#185990" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.25,18.75h-6h-6.55671e-08c-0.828427,-3.62117e-08 -1.5,-0.671573 -1.5,-1.5c0,0 0,0 0,0v-10.5l1.77636e-14,2.26494e-07c-1.25089e-07,-0.828427 0.671573,-1.5 1.5,-1.5h15l-6.55671e-08,1.77636e-15c0.828427,-3.62117e-08 1.5,0.671573 1.5,1.5v1.5"></path>
                    <path fill="none" stroke="#185990" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18.266,5.647l-2.821,-4.229l1.23093e-08,1.84657e-08c-0.277582,-0.416412 -0.744551,-0.666962 -1.245,-0.668h-8.9l8.98727e-09,-6.52811e-13c-0.501513,3.6431e-05 -0.969828,0.250705 -1.248,0.668l-2.818,4.229"></path>
                    <path fill="none" stroke="#185990" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.75,0.75v4.5"></path>
                  </g>
                </svg>
                <svg v-if="operationGroup.kind === 'inventory_add'" width="26" height="26" version="1.1" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g transform="scale(1.08333)">
                    <path fill="none" stroke="#9F7EE7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.25,17.25l6.75016e-14,9.05975e-07c-5.00356e-07,-3.31371 2.68629,-6 6,-6c3.31371,-5.00356e-07 6,2.68629 6,6c5.00356e-07,3.31371 -2.68629,6 -6,6l-2.62268e-07,-7.10543e-15c-3.31371,-1.44847e-07 -6,-2.68629 -6,-6c0,0 0,-3.55271e-15 0,-3.55271e-15"></path>
                    <path fill="none" stroke="#9F7EE7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.25,20.25v-6"></path>
                    <path fill="none" stroke="#9F7EE7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.25,14.25l-2.25,2.25"></path>
                    <path fill="none" stroke="#9F7EE7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.25,14.25l2.25,2.25"></path>
                    <path fill="none" stroke="#185990" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.25,18.75h-6h-6.55671e-08c-0.828427,-3.62117e-08 -1.5,-0.671573 -1.5,-1.5c0,0 0,0 0,0v-10.5l1.77636e-14,2.26494e-07c-1.25089e-07,-0.828427 0.671573,-1.5 1.5,-1.5h15l-6.55671e-08,1.77636e-15c0.828427,-3.62117e-08 1.5,0.671573 1.5,1.5v1.5"></path>
                    <path fill="none" stroke="#185990" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18.266,5.647l-2.821,-4.229l1.23093e-08,1.84657e-08c-0.277582,-0.416412 -0.744551,-0.666962 -1.245,-0.668h-8.9l8.98727e-09,-6.52811e-13c-0.501513,3.6431e-05 -0.969828,0.250705 -1.248,0.668l-2.818,4.229"></path>
                    <path fill="none" stroke="#185990" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.75,0.75v4.5"></path>
                  </g>
                </svg>
                <svg v-if="operationGroup.kind === 'inventory_remove'" width="26" height="26" version="1.1" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g transform="scale(1.08333)">
                    <path fill="none" stroke="#9F7EE7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M21.4926,13.0074c2.34315,2.34315 2.34315,6.14214 0,8.48528c-2.34315,2.34315 -6.14214,2.34315 -8.48528,0c-2.34315,-2.34315 -2.34315,-6.14214 -1.77636e-15,-8.48528c2.34315,-2.34315 6.14214,-2.34315 8.48528,-1.77636e-15"></path>
                    <path fill="none" stroke="#9F7EE7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.25,14.25v6"></path>
                    <path fill="none" stroke="#9F7EE7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.25,20.25l-2.25,-2.25"></path>
                    <path fill="none" stroke="#9F7EE7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.25,20.25l2.25,-2.25"></path>
                    <path fill="none" stroke="#185990" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.25,18.75h-6h-6.55671e-08c-0.828427,-3.62117e-08 -1.5,-0.671573 -1.5,-1.5c0,0 0,0 0,0v-10.5l1.77636e-14,2.26494e-07c-1.25089e-07,-0.828427 0.671573,-1.5 1.5,-1.5h15l-6.55671e-08,1.77636e-15c0.828427,-3.62117e-08 1.5,0.671573 1.5,1.5v1.5"></path>
                    <path fill="none" stroke="#185990" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18.266,5.647l-2.821,-4.229l1.23093e-08,1.84657e-08c-0.277582,-0.416412 -0.744551,-0.666962 -1.245,-0.668h-8.9l8.98727e-09,-6.52811e-13c-0.501513,3.6431e-05 -0.969828,0.250705 -1.248,0.668l-2.818,4.229"></path>
                    <path fill="none" stroke="#185990" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.75,0.75v4.5"></path>
                  </g>
                </svg>
              </div>
              <div class="flex-grow">
                {{formatLabel(operationGroup)}}
                <div class="text-xs leading-tight pb-1 text-gray-700" v-if="operationGroup.operations.length > 1">
                  {{formatOperationArticles(operationGroup, !isNested)}}
                </div>
                <div class="text-sm mb-1 text-blue-500" v-if="isNested && operationGroup.internalNote">
                  <span class="font-semibold">
                    Note :
                  </span>
                  <span>
                    {{operationGroup.internalNote}}
                  </span>
                </div>
                <template v-if="!article">
                  <div
                    class="text-sm mb-1 text-blue-500 cursor-pointer hover:underline"
                    @click.stop="$router.push({name: 'card', params: {id: operationGroup.card.id}})"
                    v-if="operationGroup.card">
                    <span class="font-semibold">
                      Carte AS24 :
                    </span>
                    <span>
                      {{operationGroup.card.identifier}}
                    </span>
                  </div>
                  <div
                    class="text-sm mb-1 text-blue-500 cursor-pointer hover:underline"
                    @click.stop="$router.push({name: 'phone', params: {id: operationGroup.phone.id}})"
                    v-if="operationGroup.phone">
                    <span class="font-semibold">
                      Téléphone :
                    </span>
                    <span>
                      {{operationGroup.phone.identifier}}
                    </span>
                  </div>
                  <div
                    class="text-sm mb-1 text-blue-500 cursor-pointer hover:underline"
                    @click.stop="$router.push({name: 'badge', params: {id: operationGroup.badge.id}})"
                    v-if="operationGroup.badge">
                    <span class="font-semibold">
                      Badge :
                    </span>
                    <span>
                      {{operationGroup.badge.identifier}}
                    </span>
                  </div>
                </template>
              </div>
            </div>
          </td>
          <td v-if="!isNested" class="border px-4 py-1 w-1/3">
            {{operationGroup.employee.fullName}}
          </td>
          <td class="border px-4 py-1 w-1/5">
            {{operationGroup.site}}
          </td>
          <td v-if="!isNested" class="border px-2">
            <button class="mf-secondary-button leading-normal">
              Voir  ↗
            </button>
          </td>
          <td v-if="canBeCancelled(operationGroup)" class="border px-2">
            <button class="mf-secondary-button leading-normal" @click="cancelOperationGroup(operationGroup)">
              Annuler
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="text-gray-600" v-else>
      Aucun mouvement.
    </p>
  </div>
</template>

<script>
import { isAfterDays, formatOperationType, formatOperationArticles, formatDate, pluralize } from '@utils/format'

export default {
  props: {
    isCancellable: Boolean,
    isNested: Boolean,
    operationGroups: Array,
    article: Object
  },
  methods: {
    formatOperationType,
    formatOperationArticles,
    formatDate,
    getArticleLabel(operationGroup, total) {
      if (operationGroup.operations.length === 1) {
        return formatOperationArticles(operationGroup, true)
      }
      else {
        return total + ' ' + pluralize('article', total)
      }
    },
    formatLabel(operationGroup) {
      const total = Math.abs(operationGroup.quantityDiffTotal)
      return formatOperationType(operationGroup.kind) +
        ' de ' +
        this.getArticleLabel(operationGroup, total)
    },
    canBeCancelled(operationGroup) {
      return this.isCancellable &&
        (this.operationGroups.length && this.operationGroups[0] === operationGroup) &&
        isAfterDays(operationGroup.operationDate, 30)
    },
    cancelOperationGroup(operationGroup) {
      if (window.confirm('Cette opération sera annulée et les articles reviendront à leur état précédent.\nSouhaitez-vous continuer ?')) {
        this.$store.dispatch('operations/deleteOperationGroup', operationGroup).then(() => {
          this.$router.go()
        }).catch(() => {
          alert('Erreur durant l’annulation de l’opération.')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
