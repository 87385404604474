<template>
  <table class="table-auto w-full">
    <!-- <thead>
      <tr>
        <th>Salarié</th>
      </tr>
    </thead> -->
    <tbody>
      <tr
        v-for="employee in employees"
        :key="employee.id"
        class="odd:bg-gray-100 hover:bg-indigo-100 cursor-pointer"
        :class="{'selected': value && (value.id === employee.id)}"
        @click="select(employee)">
        <td class="border px-3 py-1 w-full border-r-0">
          <div class="rounded-full inline-block w-8 h-8 mr-1 text-sm leading-none font-semibold bg-indigo-100 text-indigo-500 justify-center items-center inline-flex">
            <span class="mb-1">{{employee.initials}}</span>
          </div>
          <span class="align-middle">
            {{employee.fullName}}
          </span>
        </td>
        <td class="whitespace-no-wrap text-gray-600 border-l-0 border px-3 py-1 text-right">
          {{employee.site}}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    isSelectable: Boolean,
    employees: Array,
    value: Object
  },
  methods: {
    select(employee) {
      if (this.isSelectable) {
        this.$emit('input', employee)
      }
      else {
        this.$router.push({ name: 'employee', params: { id: employee.id } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.selected {
  @apply bg-indigo-200;
}
</style>
