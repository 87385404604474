<template>
  <div v-if="employee" class="mf-container">
    <div class="md:flex mt-2">
      <div class="md:flex-shrink-0">
        <div class="rounded-full w-20 h-20 text-3xl text-center leading-none font-semibold bg-indigo-100 text-indigo-500 justify-center items-center flex">
          <span class="mb-1">{{employee.initials}}</span>
        </div>
      </div>
      <div class="md:flex-grow mt-4 md:mt-0 md:ml-6">
        <h2 class="pb-1">
          {{employee.fullName}}
          <span v-if="employee.isDeleted" class="text-gray-500 ml-2">(Supprimé)</span>
        </h2>
        <p class="text-gray-600">
          Site de {{employee.site}}.<br>
          Profil créé le {{formatDate(employee.createdAt, 'd MMM yy à HH:mm')}}.
        </p>
      </div>
      <div class="md:flex-shrink-0">
        <router-link
          tag="button"
          class="mf-primary-button -mt-6"
          :to="{name: 'editEmployee', params: {id: employee.id}}">
          Modifier
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@utils/format'

export default {
  props: {
    employee: Object
  },
  methods: {
    formatDate
  }
}
</script>

<style lang="scss" scoped>
</style>
