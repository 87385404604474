<template>
  <div v-if="employee" class="mf-container mt-4">
    <div v-if="$route.params.isDeliverySuccess" class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
      <strong class="font-bold mr-2">C’est tout bon !</strong>
      <span class="block sm:inline">La distribution d’articles a bien été prise en compte.</span>
    </div>
    <div v-if="$route.params.isRestitutionSuccess" class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
      <strong class="font-bold mr-2">C’est tout bon !</strong>
      <span class="block sm:inline">La restitution d’articles a bien été prise en compte.</span>
    </div>
    <div>
      <h2 class="flex">
        <span class="flex-grow">
          Mouvements
        </span>
        <router-link
          v-if="employee.operationGroups.length"
          tag="button"
          class="mf-secondary-button"
          :to="{name: 'print', params: {id: employee.id}}">
          Imprimer la fiche
        </router-link>
      </h2>
    </div>
    <operation-groups
      v-if="employee.operationGroups"
      :operationGroups="employee.operationGroups"
      :isNested="true"
      :isCancellable="true" />
  </div>
</template>

<script>
import OperationGroups from '@components/OperationGroups'

export default {
  components: { OperationGroups },
  props: {
    employee: Object
  }
}
</script>

<style lang="scss" scoped>
</style>
