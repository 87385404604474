<template>
  <div v-if="articles">
    <div class="flex items-center pb-4 mf-no-print">
      <h1 class="flex-grow pb-0">
        <router-link :to="{name: 'stock'}">Stock</router-link>
        &nbsp;>&nbsp;
        Valorisation du stock
      </h1>
      <button
        class="mf-secondary-button ml-3"
        @click="print">
        Imprimer
      </button>
      <button
        class="mf-primary-button ml-3"
        @click="download">
        Télécharger la fiche
      </button>
    </div>
    <stock-sheet :articles="articles" @quantity="setQuantityKey" />
  </div>
</template>

<script>
import StockSheet from '@components/StockSheet'
import { prepareValuationArticles } from '@utils/display'
import { downloadExcel } from '@/utils/download'

export default {
  components: { StockSheet },
  data() {
    return {
      articles: null,
      quantityKey: 'quantityTotal'
    }
  },
  methods: {
    setQuantityKey(key) {
      debugger
      this.quantityKey = key
    },
    print() {
      window.print()
    },
    download() {
      const quantityKey = this.quantityKey
      const header = ['Article', 'Coût unitaire', 'Quantité', 'Coût total']
      const rows = this.articles.map(article => {
        const name = [
          article.name,
          article.description ? '(' + article.description + ')' : null,
          article.size ? '/ ' + article.size : null
        ].filter(i => i).join(' ')
        const cost = article.costInternal
        const quantity = article[quantityKey]
        const totalCost = cost * quantity
        return [
          name,
          cost,
          quantity,
          totalCost
        ]
      })
      downloadExcel([header].concat(rows))
    }
  },
  async created() {
    this.articles = prepareValuationArticles(await this.$store.dispatch('articles/getArticles'))
  }
}
</script>

<style lang="scss" scoped>
</style>
