import api from '@/store/api'
import { buildQuery } from '@utils/format'

const getters = {
}

const actions = {
  getPhones(_context, params) {
    const query = buildQuery(params)
    return api.get('/phones' + query)
      .then(({ data }) => {
        return data
      })
  },
  getAvailablePhones(_context) {
    return api.get('/phones?available=true')
      .then(({ data }) => {
        return data
      })
  },
  getPhone(_context, id) {
    return api.get('/phones/' + id)
      .then(({ data }) => {
        return data
      })
  },
  createPhone(_context, phone) {
    return api.post('/phones', phone)
      .then(({ data }) => {
        return data
      })
  },
  updatePhone(_context, phone) {
    return api.put('/phones/' + phone.id, phone)
      .then(({ data }) => {
        return data
      })
  },
  removePhone(_context, phone) {
    return api.delete('/phones/' + phone.id)
      .then(({ data }) => {
        return data
      })
  }
}

export default {
  namespaced: true,
  getters,
  actions
}
