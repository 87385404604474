import axios from 'axios'
import defaultsConfig from 'axios/lib/defaults'
import transformKeys from 'transform-keys'
import camelCase from 'lodash.camelcase'
import snakeCase from 'lodash.snakecase'

function transformRequest(data) {
  if (data) {
    data = transformKeys(data, snakeCase)
  }
  return data
}

function transformResponse(data) {
  if (data) {
    data = transformKeys(data, camelCase)
  }
  return data
}

const CSRF_TOKEN = document.querySelector('[name="csrf-token"]').content
const api = axios.create({
  baseURL: '/api',
  headers: {
    'X-CSRF-Token': CSRF_TOKEN
  },
  transformRequest: [transformRequest, defaultsConfig.transformRequest[0]],
  transformResponse: [defaultsConfig.transformResponse[0], transformResponse]
})

export default api
