<template>
  <div v-if="employee">
    <div class="flex items-center pb-4 mf-no-print">
      <h1 class="flex-grow pb-0">
        <router-link :to="{name: 'employee', params: {id: employee.id}}">{{employee.fullName}}</router-link>
        &nbsp;>&nbsp;
        Fiche de prise en compte du matériel
      </h1>
      <button
        class="mf-primary-button ml-3"
        @click="print">
        Imprimer la fiche
      </button>
    </div>
    <employee-sheet :employee="employee" />
  </div>
</template>

<script>
import EmployeeSheet from '@components/EmployeeSheet'

export default {
  components: { EmployeeSheet },
  data() {
    return {
      employee: null
    }
  },
  methods: {
    print() {
      window.print()
    }
  },
  async created() {
    const { id } = this.$route.params
    try {
      this.employee = await this.$store.dispatch('employees/getEmployee', id)
    }
    catch (e) {
      this.$router.push({ name: 'employees' })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
