<template>
  <div>
    <div class="flex items-center pb-4">
      <h1 class="flex-grow pb-0">Salariés</h1>
      <router-link
        tag="button"
        class="mf-secondary-button"
        :to="{name: 'createEmployee'}">
        Ajouter un salarié
      </router-link>
    </div>
    <div class="mf-container" v-if="employees.length || searchQuery">
      <div class="flex mb-5 items-center">
        <h2 class="flex-grow p-0">Liste des salariés</h2>
        <div>
          <input
            class="appearance-none border border-blue-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-300 text-left"
            type="text"
            placeholder="Rechercher un salarié…"
            v-model="searchQuery"
            @input="loadEmployees">
        </div>
      </div>
      <employees v-if="employees.length" class="mb-5" :employees="employees" />
      <p v-else class="text-gray-600">Aucun salarié trouvé.</p>
      <div v-if="filterQuery == 'recent' && !searchQuery" class="text-gray-600">
        <hr class="mt-2 mb-4">
        Les salariés des 18 derniers mois sont affichés.
        <button class="mf-secondary-button leading-normal ml-2" @click="loadAll">
          Afficher tout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Employees from '@components/Employees'

export default {
  components: { Employees },
  data() {
    return {
      employees: [],
      searchQuery: location.hash.substr(1) || null,
      filterQuery: 'recent'
    }
  },
  methods: {
    async loadEmployees() {
      const filter = this.filterQuery
      const query = this.searchQuery
      this.employees = await this.$store.dispatch('employees/getEmployees', { filter, query })
    },
    loadAll() {
      this.filterQuery = 'all'
      this.loadEmployees()
    }
  },
  watch: {
    searchQuery() {
      if (history.pushState) {
        history.pushState(null, null, '#' + this.searchQuery)
      }
    }
  },
  async created() {
    this.loadEmployees()
  }
}
</script>

<style lang="scss" scoped>
</style>
