<template>
  <div v-if="employee" class="mf-container mt-4">
    <h2>En possession</h2>
    <articles :articles="articles" />
  </div>
</template>

<script>
import Articles from '@components/Articles'
import { getStockFromOperationGroups } from '@utils/display'

export default {
  components: { Articles },
  props: {
    employee: Object
  },
  computed: {
    articles() {
      return getStockFromOperationGroups(this.employee.operationGroups)
    }
  }

}
</script>

<style lang="scss" scoped>
</style>
