<template>
  <div>
    <div class="flex items-center pb-4">
      <h1 class="flex-grow pb-0">Cartes AS24</h1>
      <router-link
        tag="button"
        class="mf-primary-button ml-3"
        :to="{name: 'createCard'}">
        Ajouter une carte
      </router-link>
    </div>
    <div class="mf-container" v-if="cards.length || searchQuery">
      <div class="float-right">
        <input
          class="appearance-none border border-blue-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-300 text-left"
          type="text"
          placeholder="Rechercher une carte…"
          v-model="searchQuery"
          @input="loadCards">
      </div>
      <div v-for="group in cards" :key="group.key">
        <h2>{{group.key}}</h2>
        <cards class="mb-5" :cards="group.cards" />
      </div>
      <p v-if="!cards.length" class="text-gray-600 pt-2">Aucune carte trouvée.</p>
      <div v-if="filterQuery == 'default' && !searchQuery" class="text-gray-600">
        <hr class="mt-2 mb-4">
        Les cartes AS24 perdues ou usées ne sont pas affichées.
        <button class="mf-secondary-button leading-normal ml-2" @click="loadAll">
          Afficher tout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Cards from '@components/Cards'
import groupBy from 'lodash.groupby'

function groupCardsBySite(cards) {
  const groupedCards = groupBy(cards, c => c.employee ? c.employee.site : 'À distribuer')
  return Object.keys(groupedCards).map(key => ({
    key,
    cards: groupedCards[key]
  }))
}

export default {
  components: { Cards },
  data() {
    return {
      cards: [],
      searchQuery: location.hash.substr(1) || null,
      filterQuery: 'default'
    }
  },
  methods: {
    async loadCards() {
      const query = this.searchQuery
      const filter = this.filterQuery
      this.cards = groupCardsBySite(await this.$store.dispatch('cards/getCards', { query, filter }))
    },
    loadAll() {
      this.filterQuery = 'all'
      this.loadCards()
    }
  },
  watch: {
    searchQuery() {
      if (history.pushState) {
        history.pushState(null, null, '#' + this.searchQuery)
      }
    }
  },
  async created() {
    this.loadCards()
  }
}
</script>

<style lang="scss" scoped>
</style>
