<template>
  <div class="mf-container" v-if="articles">
    <header class="flex justify-between items-center">
      <div class="w-1/3">
        <img src="~@images/main-forte-logo.jpg" class="h-16">
      </div>
      <div class="w-1/3text-center">
        <h1 class="p-0">Valorisation du stock</h1>
      </div>
      <div class="w-1/3 text-right text-gray-600">
        <div class="mf-segmented-buttons mf-no-print">
          <div
            class="mf-segmented-button"
            :class="{active: quantityKey === 'quantityAvailable'}"
            @click="setQuantityKey('quantityAvailable')">Stock vestiaire</div>
          <div
            class="mf-segmented-button"
            :class="{active: quantityKey === 'quantityTotal'}"
            @click="setQuantityKey('quantityTotal')">Stock global</div>
        </div>
      </div>
    </header>
    <p class="px-4 py-2 font-bold"></p>
    <div>
      <table class="table-auto w-full mb-3">
        <thead>
          <tr class="whitespace-no-wrap">
            <th class="w-2/3">Article</th>
            <th class="text-center">Coût unitaire</th>
            <th class="text-center">Quantité</th>
            <th class="text-center">Coût total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="article in articles" :key="article.id">
            <td>
              {{article.name}}
              <template v-if="article.description">
                ({{article.description}})
              </template>
              <template v-if="article.size">
                /
                {{article.size}}
              </template>
            </td>
            <td class="text-center">{{article.costInternal | formatFloat}} €</td>
            <td class="text-center">{{article[quantityKey]}}</td>
            <td class="text-center">{{article[quantityKey] * article.costInternal | formatFloat}} €</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="2" class="border-none"></td>
            <td class="footer text-center">Coût total</td>
            <td class="footer text-center">{{totalCost}}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    articles: Array
  },
  data() {
    return {
      quantityKey: 'quantityTotal'
    }
  },
  computed: {
    totalCost() {
      if (this.articles.length) {
        return this.articles.reduce((memo, article) => {
          memo += +article.costInternal * +article[this.quantityKey]
          return memo
        }, 0) + ' €'
      }
    }
  },
  methods: {
    setQuantityKey(key) {
      this.quantityKey = key
      this.$emit('quantity', key)
    }
  }
}
</script>

<style lang="scss" scoped>
th,
.footer {
  @apply text-sm bg-gray-200 border border-black py-1 text-black font-semibold;
}

td {
  @apply text-sm border border-black px-2 py-0;
}
</style>
