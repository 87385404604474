import api from '@/store/api'
import { buildQuery } from '@utils/format'

const getters = {
}

const actions = {
  getCards(_context, params) {
    const query = buildQuery(params)
    return api.get('/cards' + query)
      .then(({ data }) => {
        return data
      })
  },
  getAvailableCards(_context) {
    return api.get('/cards?available=true')
      .then(({ data }) => {
        return data
      })
  },
  getCard(_context, id) {
    return api.get('/cards/' + id)
      .then(({ data }) => {
        return data
      })
  },
  createCard(_context, card) {
    return api.post('/cards', card)
      .then(({ data }) => {
        return data
      })
  },
  updateCard(_context, card) {
    return api.put('/cards/' + card.id, card)
      .then(({ data }) => {
        return data
      })
  },
  removeCard(_context, card) {
    return api.delete('/cards/' + card.id)
      .then(({ data }) => {
        return data
      })
  }
}

export default {
  namespaced: true,
  getters,
  actions
}
