<template>
  <div v-if="!$route.params.id || article">
    <div class="flex items-center pb-4">
      <h1 class="flex-grow pb-0">
        <template v-if="article">
          <router-link :to="{name: 'stock'}">Stock</router-link>
          &nbsp;>&nbsp;
          <router-link :to="{name: 'article', params: {id: articleModel.id}}">{{articleModel.name}}</router-link>
          &nbsp;>&nbsp;Modifier
        </template>
        <template v-else>
          Ajouter un article
        </template>
      </h1>
    </div>
    <form class="mf-container" @submit.prevent="submit">
      <div class="flex mb-4">
        <div class="flex-grow">
          <h2>Informations</h2>
          <div class="flex-grow text-gray-700">
            <template v-if="article">
              Modifiez les informations de l’article puis validez les changements :
            </template>
            <template v-else>
              Remplissez le formulaire ci-dessous puis cliquez sur ajouter.
            </template>
          </div>
        </div>
        <div class="flex-shrink-0">
          <button
            v-if="isRemovable"
            type="button"
            tag="button"
            @click="removeArticle"
            class="mf-destructive-button mr-12">
            Supprimer
          </button>
          <button
            type="button"
            tag="button"
            @click="$router.go(-1)"
            class="mf-secondary-button mr-2">
            Annuler
          </button>
          <button
            class="mf-primary-button">
            <template v-if="article">
              {{isLoading ? 'Modification en cours…' : 'Modifier' }}
            </template>
            <template v-else>
              {{isLoading ? 'Ajout en cours…' : 'Ajouter' }}
            </template>
          </button>
        </div>
      </div>
      <div>
        <div class="flex flex-wrap -mx-3 mb-4">
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Nom
            </label>
            <input v-if="isNameEditable" ref="name" v-model="articleModel.name" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Nom de l’article" autocomplete="no">
            <div class="py-3" v-else>
              {{articleModel.name}}
            </div>
          </div>
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0" v-if="articleModel.size">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Size
            </label>
            <div class="py-3">
              {{articleModel.size}}
            </div>
          </div>
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Description
            </label>
            <input v-model="articleModel.description" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Description" autocomplete="no">
          </div>
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="subscription">
              Catégorie du stock
            </label>
            <div class="relative">
              <select
                v-model="articleModel.category"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <option v-for="category in categories" :key="category" :value="category">{{category}}</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Sites de distribution
            </label>
            <input v-model="articleModel.sites" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Sites" autocomplete="no">
          </div>
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0" v-if="!article">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Quantité initiale
            </label>
            <input v-model="articleModel.quantity" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="number" min="0" autocomplete="no">
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-4">
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Stock faible
            </label>
            <input v-model="articleModel.quantityLow" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="number" min="0" autocomplete="no">
          </div>
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Coût réel
              <label class="text-gray-500 ml-1">
                En euros
              </label>
            </label>
            <input v-model="articleModel.costInternal" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Coût de l’article" autocomplete="no">
          </div>
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Coût affiché
              <label class="text-gray-500 ml-1">
                En euros
              </label>
            </label>
            <input v-model="articleModel.costPublic" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Coût de l’article" autocomplete="no">
          </div>
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 -mr-4">
              Restitution optionnelle
            </label>
            <div class="py-3 underline cursor-pointer" @click="articleModel.isReturnable = !articleModel.isReturnable">
              {{!articleModel.isReturnable ? 'Oui' : 'Non'}}
            </div>
          </div>
          <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Gestion stock externe
            </label>
            <div class="py-3 underline cursor-pointer" @click="articleModel.isExternalStock = !articleModel.isExternalStock">
              {{articleModel.isExternalStock ? 'Oui' : 'Non'}}
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="mf-container mt-4" v-if="isInventoryEditable">
      <div class="md:flex">
        <div class="flex-grow">
          <h2>Inventaire</h2>
          <p class="mb-4">Choisissez combien d’articles à ajouter ou supprimer du stock :</p>
          <div class="px-3 mb-6">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="last-name">
              Salarié
            </label>
            <div class="w-2/3">
              <employee-picker v-model="inventoryModel.employee" @input="onEmployeeChange" />
            </div>
          </div>
          <div class="px-3 mb-6">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="last-name">
              Note interne
            </label>
            <textarea
              v-model="inventoryModel.internalNote"
              class="appearance-none block w-2/3 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Cette note s’affichera uniquement dans l’outil."
              rows="2"></textarea>
          </div>
          <form class="px-3 mb-6" @submit.prevent="submitInventoryAdd">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Quantité à ajouter
            </label>
            <input v-model="addQuantityModel" class="appearance-none w-1/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="number" min="0" autocomplete="no">
            <button
              class="mf-primary-button ml-4">
              {{isLoadingAdd ? 'Ajout en cours…' : 'Ajouter' }}
            </button>
          </form>
          <form class="px-3" @submit.prevent="submitInventoryRemove">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Quantité à supprimer
            </label>
            <input v-model="removeQuantityModel" class="appearance-none w-1/5 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="number" min="0" autocomplete="no">
            <button
              class="mf-primary-button ml-4">
              {{isLoadingRemove ? 'Suppression en cours…' : 'Supprimer' }}
            </button>
          </form>
        </div>
        <div class="md:flex-shrink-0 rounded-lg px-2 h-20 text-center leading-tight font-semibold bg-indigo-100 text-indigo-500 justify-center items-center flex flex-col">
          <span type="text" class="inline-block text-center leading-tight font-semibold rounded w-24 mb-1 text-2xl bg-indigo-100 text-indigo-500 border border-indigo-100">
            {{articleModel.quantityAvailable}}
            <span class="ml-2 text-green-500" v-if="addQuantityModel">+{{addQuantityModel}}</span>
            <span class="ml-2 text-orange-500" v-if="removeQuantityModel">-{{removeQuantityModel}}</span>
          </span>
          <span class="text-sm text-blue-600">Stock vestiaire</span>
        </div>
        <div class="md:ml-3 md:flex-shrink-0 rounded-lg px-2 h-20 text-center leading-tight font-semibold bg-indigo-100 text-indigo-500 justify-center items-center flex flex-col">
          <span type="text" class="inline-block text-center leading-tight font-semibold rounded w-24 mb-1 text-2xl bg-indigo-100 text-indigo-500 border border-indigo-100">
            {{articleModel.quantityTotal}}
            <span class="ml-2 text-green-500" v-if="addQuantityModel">+{{addQuantityModel}}</span>
            <span class="ml-2 text-orange-500" v-if="removeQuantityModel">-{{removeQuantityModel}}</span>
          </span>
          <span class="text-sm text-blue-500">Stock global</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EmployeePicker from '@components/EmployeePicker'
import { validateInputs, validateSiteInput } from '@/utils/operations'
import { formatToday } from '@utils/format'

export default {
  components: {
    EmployeePicker
  },
  data() {
    return {
      isLoading: false,
      isLoadingAdd: false,
      isLoadingRemove: false,
      isExternalStock: false,
      article: null,
      articleModel: {
        name: null,
        size: null,
        description: null,
        costPublic: 10.0,
        costInternal: 10.0,
        quantity: 10,
        quantityLow: 5,
        isReturnable: true,
        isExternalStock: false,
        category: 'CDDI',
        sites: [
          'Harnes',
          'Lille',
          'Plessis',
          'Vitrolles'
        ]
      },
      inventoryModel: {
        kind: 'inventory_add',
        site: '',
        internalNote: null,
        operationDate: formatToday(),
        employee: {},
        cardId: null,
        phoneId: null,
        badgeId: null
      },
      addQuantityModel: 0,
      removeQuantityModel: 0
    }
  },
  computed: {
    ...mapGetters({
      categories: 'articles/getCategories',
      sites: 'articles/getSites'
    }),
    isSpecialArticle() {
      return ['Badge Harnes', 'Badge Lille', 'Badge Vitrolles', 'Carte AS24', 'Téléphone'].includes(this.articleModel.name)
    },
    isInventoryEditable() {
      return this.article && !this.isSpecialArticle && !this.isExternalStock
    },
    isNameEditable() {
      return !this.articleModel.size &&
        !this.isSpecialArticle
    },
    isRemovable() {
      return this.article && !this.isSpecialArticle
    }
  },
  methods: {
    onEmployeeChange() {
      this.inventoryModel.site = this.inventoryModel.employee.site
    },
    async submitInventoryAdd() {
      const article = { ...this.articleModel, quantity: -this.addQuantityModel }
      const articles = [article]
      if (validateInputs(this.inventoryModel.employee.id) && articles.length && this.addQuantityModel > 0) {
        let model = { ...this.inventoryModel, articles }
        this.isLoadingAdd = true
        const { id } = await this.$store.dispatch('operations/createOperationGroup', model)
        this.isLoadingAdd = false
        this.$router.push({ name: 'article', params: { id: this.articleModel.id, isInventoryAddSuccess: true, operationGroupId: id } })
      }
      else {
        alert('Veuillez sélectionner un salarié et une quantité d’articles.')
      }
    },
    async submitInventoryRemove() {
      const article = { ...this.articleModel, quantity: +this.removeQuantityModel }
      const articles = [article]
      if (validateInputs(this.inventoryModel.employee.id) && articles.length && this.removeQuantityModel > 0) {
        if (article.quantityAvailable - article.quantity < 0) {
          alert('Il n’est pas possible de supprimer plus d’article que la quantité disponible en stock vestiaire.')
          return
        }
        let model = { ...this.inventoryModel, kind: 'inventory_remove', articles }
        this.isLoadingRemove = true
        const { id } = await this.$store.dispatch('operations/createOperationGroup', model)
        this.isLoadingRemove = false
        this.$router.push({ name: 'article', params: { id: this.articleModel.id, isInventoryRemoveSuccess: true, operationGroupId: id } })
      }
      else {
        alert('Veuillez sélectionner un salarié et une quantité d’articles.')
      }
    },
    async submit() {
      if (validateInputs(this.articleModel.name)) {
        if (!validateSiteInput(this.articleModel, this.sites)) {
          return
        }
        // Handle , as decimal separator
        if (typeof this.articleModel.costInternal === 'string') {
          this.articleModel.costInternal = +this.articleModel.costInternal.replace(',', '.')
        }
        if (typeof this.articleModel.costPublic === 'string') {
          this.articleModel.costPublic = +this.articleModel.costPublic.replace(',', '.')
        }
        this.isLoading = true
        const article = await this.$store.dispatch(this.article ? 'articles/updateArticle' : 'articles/createArticle', this.articleModel)
        this.isLoading = false
        this.$router.push({ name: 'article', params: { id: article.id } })
      }
      else {
        alert('Veuillez remplir tous les éléments du formulaire.')
      }
    },
    async removeArticle() {
      if (window.confirm('La suppression fera disparaître l’article du stock et des articles à distribuer.\nIl apparaîtra toujours dans l’historique des mouvements.\n\nSouhaitez-vous continuer ?')) {
        await this.$store.dispatch('articles/removeArticle', this.articleModel)
        alert('La suppression a bien été effectuée !')
        this.$router.push({ name: 'stock' })
      }
    }
  },
  mounted() {
    if (this.$refs.name) {
      this.$refs.name.focus()
    }
  },
  async created() {
    const { id } = this.$route.params
    if (id) {
      try {
        this.article = await this.$store.dispatch('articles/getArticle', id)
        this.articleModel = { ...this.article }
        this.isExternalStock = this.articleModel.isExternalStock
      }
      catch (e) {
        this.$router.push({ name: 'stock' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
