<template>
  <div v-if="article && phone">
    <div class="flex items-center pb-4">
      <h1 class="flex-grow pb-0">
        <router-link :to="{name: 'phones'}">Téléphones</router-link>
        &nbsp;>&nbsp;
        {{phone.identifier}}<br>
      </h1>
      <router-link
        tag="button"
        class="mf-primary-button ml-3"
        :to="{name: 'editPhone', params: {id: phone.id}}">
        Modifier
      </router-link>
    </div>
    <div class="mf-container">
      <div class="md:flex">
        <div class="mt-4 md:mt-0 flex-grow">
          <h2 class="pb-1 inline-block">
            Téléphone
            <span class="text-gray-500 ml-2">N° {{phone.identifier}}</span>
            <span v-if="phone.isLost" class="text-red-400 ml-2">PERDU / USÉ</span>
          </h2>
          <div class="text-gray-600">
            {{phone.subscription}}
          </div>
        </div>
        <div class="md:ml-3 rounded-lg px-2 h-20 text-center leading-none font-semibold bg-indigo-100 text-indigo-500 justify-center items-center flex flex-col">
          <span class="mb-2 text-2xl">{{phone.codePin}}</span>
          <span class="text-sm text-blue-600">Code Pin</span>
        </div>
        <div class="md:ml-3 rounded-lg px-2 h-20 text-center leading-none font-semibold bg-indigo-100 text-indigo-500 justify-center items-center flex flex-col">
          <span class="mb-2 text-2xl">{{phone.codePuk}}</span>
          <span class="text-sm text-blue-600">Code Puk</span>
        </div>
      </div>
    </div>
    <article-operation-groups :article="article" :phone="phone" />
    <article-employees :employees="employees" />
  </div>
</template>

<script>
import ArticleOperationGroups from '@components/ArticleOperationGroups'
import ArticleEmployees from '@components/ArticleEmployees'

export default {
  components: { ArticleOperationGroups, ArticleEmployees },
  data() {
    return {
      article: null,
      phone: null
    }
  },
  computed: {
    employees() {
      return this.phone.employee ? [this.phone.employee] : []
    }
  },
  async created() {
    const { id } = this.$route.params
    try {
      this.phone = await this.$store.dispatch('phones/getPhone', id)
      this.article = await this.$store.dispatch('articles/getArticleByName', 'Téléphone')
    }
    catch (e) {
      this.$router.push({ name: 'stock' })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
