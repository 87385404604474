<template>
  <div>
    <div class="flex items-center pb-4">
      <h1 class="flex-grow pb-0">Articles en stock</h1>
      <div class="mf-segmented-buttons">
        <div
          class="mf-segmented-button"
          :class="{active: quantityKey === 'quantityAvailable'}"
          @click="quantityKey = 'quantityAvailable'">Stock vestiaire</div>
        <div
          class="mf-segmented-button"
          :class="{active: quantityKey === 'quantityTotal'}"
          @click="quantityKey = 'quantityTotal'">Stock global</div>
      </div>
      <router-link
        tag="button"
        class="mf-secondary-button ml-6"
        :to="{name: 'restitution'}">
        Restituer du matériel
      </router-link>
      <router-link
        tag="button"
        class="mf-primary-button ml-3"
        :to="{name: 'delivery'}">
        Distribuer du matériel
      </router-link>
    </div>
    <div class="mf-container" v-if="articles.length">
      <articles :articles="articles" :quantityKey="quantityKey" :showCategory="true" />
      <hr class="mt-2 mb-4">
      <div class="flex justify-between items-center">
        <div></div>
        <div>
          <button
            class="mf-secondary-button ml-3"
            @click="openHelp">
            Consulter l’aide
          </button>
          <router-link
            tag="button"
            class="mf-secondary-button ml-3"
            :to="{name: 'valuation'}">
            Valoriser le stock
          </router-link>
          <router-link
            tag="button"
            class="mf-secondary-button ml-3"
            :to="{name: 'createArticle'}">
            Ajouter un article
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Articles from '@components/Articles'
import { prepareStockArticles } from '@utils/display'

export default {
  components: { Articles },
  data() {
    return {
      articles: [],
      quantityKey: 'quantityAvailable'
    }
  },
  methods: {
    openHelp() {
      window.open('https://www.notion.so/Documentation-de-l-outil-de-Gestion-des-Stocks-Main-Forte-28e59f69102f4a68a55836724c8dfda4', '_blank')
    }
  },
  async created() {
    this.articles = prepareStockArticles(await this.$store.dispatch('articles/getArticles'))
  }
}
</script>

<style lang="scss" scoped>
</style>
