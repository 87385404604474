<template>
  <div>
    <div class="mf-container max-w-md mx-auto text-center">
      <h2>Bienvenue !</h2>
      <p>Veuillez vous connecter pour accéder à l’outil :</p>
      <form @submit.prevent="submit">
        <p>
          <input ref="password" v-model="password" class="appearance-none block mx-auto bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="password" placeholder="Mot de passe">
          <button
            class="mf-primary-button">
            {{isLoading ? 'Connexion…' : 'Se connecter' }}
          </button>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      password: null
    }
  },
  methods: {
    async submit() {
      this.isLoading = true
      const isConnected = await this.$store.dispatch('account/signin', this.password)
      this.isLoading = false
      if (isConnected) {
        this.$router.replace('/')
      }
      else {
        alert('Désolé, le mot de passe est invalide.')
      }
    }
  },
  mounted() {
    this.$refs.password.focus()
  }
}
</script>

<style lang="scss" scoped>
</style>
