<template>
  <div>
    <div
      v-if="articles && articles.length"
      class="md:mf-2-columns lg:mf-3-columns mb-5">
      <div
        v-for="(article, i) in articles"
        :key="article.id">
        <label
          v-if="showCategory && (i == 0 || article.category !== articles[i - 1].category)"
          class="block uppercase tracking-wide text-blue-500 text-sm font-bold mb-2"
          :class="{'pt-2': i > 0}">
          {{article.category}}
        </label>
        <div
          class="bg-indigo-100 rounded flex mb-2 p-1 px-2 pr-1"
          :class="!article.articles && 'hover:bg-indigo-200 cursor-pointer'"
          @click="!article.articles && openArticle(article)">
          <div class="flex-grow leading-snug">
            <span class="pr-1">{{article.name}}</span>
            <span class="text-gray-600 text-sm pr-1" v-if="article.size">{{article.size}}</span>
            <span class="text-gray-600 text-sm">{{article.description}}</span>
          </div>
          <template v-if="article.articles">
            <div class="flex-shrink-0 mf-sizes-columns">
              <div
                v-for="subArticle in article.articles"
                :key="subArticle.id"
                class="inline-block flex items-center rounded text-center ml-2 mb-1 hover:bg-indigo-200 cursor-pointer"
                @click="openArticle(subArticle)">
                <div class="flex-grow text-left text-gray-800 text-sm pr-1">{{subArticle.size}}</div>
                <article-count
                  :article="subArticle"
                  :quantityKey="quantityKey"
                  :showLowQuantity="showCategory" />
              </div>
            </div>
          </template>
          <article-count
            v-else
            :article="article"
            :quantityKey="quantityKey"
            :showLowQuantity="showCategory" />
        </div>
      </div>
    </div>
    <p v-else class="text-gray-600">
      Pas d'articles.
    </p>
  </div>
</template>

<script>
import ArticleCount from '@components/ArticleCount'

export default {
  components: { ArticleCount },
  props: {
    showCategory: Boolean,
    articles: Array,
    quantityKey: String
  },
  methods: {
    openArticle(article) {
      this.$router.push({ name: 'article', params: { id: article.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.mf-sizes-columns {
  column-count: 2;
  column-gap: 0;

  * {
    break-inside: avoid;
  }
}

</style>
