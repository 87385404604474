<template>
  <div class="mf-container mt-4" v-if="isLoaded">
    <h2>En possession de l’article</h2>
    <employees class="mb-3" :employees="computedEmployees" v-if="computedEmployees.length" />
    <p class="text-gray-600" v-else>
      Aucun salarié.
    </p>
  </div>
</template>

<script>
import Employees from '@components/Employees'
import { getEmployeesFromOperationGroups } from '@utils/display'

export default {
  components: { Employees },
  props: {
    article: Object,
    employees: Array
  },
  data() {
    return {
      computedEmployees: [],
      isLoaded: false
    }
  },
  methods: {
    async loadEmployees() {
      if (this.employees) {
        this.computedEmployees = this.employees
      }
      else {
        const article = this.article && this.article.id
        const filter = 'employees'
        const operationGroups = await this.$store.dispatch('operations/getOperationGroups', { article, filter })
        this.computedEmployees = getEmployeesFromOperationGroups(operationGroups)
      }
      this.isLoaded = true
    }
  },
  created() {
    this.loadEmployees()
  }
}
</script>

<style lang="scss" scoped>

</style>
