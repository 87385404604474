<template>
  <div>
    <h1>Formulaire de restitution</h1>
    <div class="mf-container">
      <div class="flex mb-4 items-center">
        <div class="flex-grow text-gray-700">
          Sélectionnez un salarié pour afficher sa liste des article à restituer.<br>
          Vous pourrez imprimer la fiche de prise en compte après la validation.
        </div>
        <div class="flex-shrink-0">
          <router-link
            tag="button"
            :to="{name: 'operations'}"
            class="mf-secondary-button mr-2">
            Annuler
          </router-link>
          <button
            class="mf-primary-button"
            @click="submit">
            {{isLoading ? 'Validation en cours…' : 'Valider la restitution' }}
          </button>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1">
          <h2 class="inline-block">Salarié</h2>
          <div>
            <employee-picker v-model="model.employee" @input="onEmployeeChange" />
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-zip">
                Date de restitution
              </label>
              <input v-model="model.operationDate" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 pl-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-zip" type="date" placeholder="2/11/2019">
            </div>
          </div>
          <div>
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="last-name">
              Note interne
            </label>
            <textarea
              v-model="model.internalNote"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Cette note s’affichera uniquement dans l’outil."
              rows="2"></textarea>
          </div>
        </div>
        <div class="flex-1 pl-12">
          <div class="mb-6">
            <h2>Articles perdus ou usés</h2>
            <articles-picker
              v-if="lostArticles.length"
              :articles="lostArticles"
              @change="updateLostArticle"
              :isRestitution="true" />
            <p class="text-gray-600" v-else>
              Pour déclarer un article comme perdu,<br>
              enlevez-le de la liste ci-dessous.
            </p>
          </div>
          <div class="flex">
            <h2 class="inline-block mr-2 flex-grow">Articles restitués</h2>
            <!-- <button
              class="mf-secondary-button flex-shrink-0 leading-normal py-0 h-6 mt-1"
              v-if="selectedArticles.length"
              @click="resetArticles">
              Tout enlever
            </button> -->
          </div>
          <articles-picker
            v-if="filteredArticles.length"
            :articles="filteredArticles"
            :isRestitution="true"
            @change="updateArticle" />
          <p class="text-gray-600 mt-2" v-else>Aucun article.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticlesPicker from '@components/ArticlesPicker'
import EmployeePicker from '@components/EmployeePicker'
import { formatToday } from '@utils/format'
import {
  filterSelectedArticles,
  validateInputs,
  updateArticles,
  updateLostArticles,
  synchronizeLostArticles,
  validateRestitutionArticles
} from '@utils/operations'

export default {
  components: { ArticlesPicker, EmployeePicker },
  data() {
    return {
      articles: [],
      lostArticles: [],
      model: {},
      isLoading: false,
      isNewEmployee: false
    }
  },
  computed: {
    filteredArticles() {
      return this.articles
    },
    selectedArticles() {
      return filterSelectedArticles(this.articles)
    }
  },
  methods: {
    updateArticle({ id, quantity }) {
      this.lostArticles = synchronizeLostArticles(this.lostArticles, this.articles, id, quantity)
      this.articles = updateArticles(this.articles, id, quantity)
    },
    updateLostArticle({ id, quantity }) {
      this.lostArticles = updateLostArticles(this.lostArticles, id, quantity)
    },
    initModel() {
      this.model = {
        kind: 'restitution',
        site: null,
        internalNote: null,
        operationDate: formatToday(),
        employee: {},
        cardId: null,
        phoneId: null,
        badgeId: null
      }
    },
    async onEmployeeChange() {
      this.model.site = this.model.employee.site
      this.articles = await this.$store.dispatch('employees/getEmployeeArticles', this.model.employee.id)
      this.lostArticles = []
    },
    resetArticles() {
      this.articles.forEach(article => {
        article.quantity = 0
      })
    },
    embedSpecialArticles(model) {
      // Please note that this will only embed the id of the first special article found per type.
      // This shouldn't be a problem since validateRestitutionArticles now checks for duplicates.
      // The id has been formatted in employees/getEmployeeArticles
      const card = model.articles.find(a => a.name === 'Carte AS24')
      const phone = model.articles.find(a => a.name === 'Téléphone')
      const badge = model.articles.find(a => ['Badge Harnes', 'Badge Lille', 'Badge Vitrolles'].includes(a.name))
      if (card) {
        card.id = card.id.split('|')[0]
        model.cardId = card.card.id
      }
      if (phone) {
        phone.id = phone.id.split('|')[0]
        model.phoneId = phone.phone.id
      }
      if (badge) {
        badge.id = badge.id.split('|')[0]
        model.badgeId = badge.badge.id
      }
      return model
    },
    embedRestitutionQuantity(articles) {
      return articles.map(article => ({
        ...article,
        quantity: -article.quantity
      }))
    },
    async submit() {
      const selectedArticles = this.embedRestitutionQuantity(this.selectedArticles)
      const lostArticles = this.embedRestitutionQuantity(filterSelectedArticles(this.lostArticles))
      if (validateInputs(this.model.employee.id) && (selectedArticles.length + lostArticles.length)) {
        if (!validateRestitutionArticles(this.articles, selectedArticles, lostArticles)) {
          return
        }
        this.isLoading = true
        let response = {}
        if (selectedArticles.length) {
          const selectedModel = this.embedSpecialArticles({ ...this.model, articles: selectedArticles })
          response = await this.$store.dispatch('operations/createOperationGroup', selectedModel)
        }
        if (lostArticles.length) {
          const lostModel = this.embedSpecialArticles({ ...this.model, articles: lostArticles, kind: 'lost' })
          response = await this.$store.dispatch('operations/createOperationGroup', lostModel)
        }
        this.isLoading = false
        this.$router.push({ name: 'employee', params: { id: this.model.employee.id, isRestitutionSuccess: true, operationGroupId: response.id } })
      }
      else {
        alert('Veuillez sélectionner un salarié et une liste d’articles.')
      }
    }
  },
  created() {
    this.initModel()
  },
  async mounted() {
  }
}
</script>

<style lang="scss" scoped>
</style>
