import sortBy from 'lodash.sortby'

export const TEE_SHIRT_SIZES = ['Taille S', 'Taille M', 'Taille L', 'Taille XL', 'Taille XXL', 'Taille 3XL', 'Taille 4XL']
export const VESTE_SIZES = ['Taille 0', 'Taille 1', 'Taille 2', 'Taille 3', 'Taille 4', 'Taille 5', 'Taille 6', 'Taille 7']
export const CHAUSSURE_SIZES = ['Taille 38', 'Taille 39', 'Taille 40', 'Taille 41', 'Taille 42', 'Taille 43', 'Taille 44', 'Taille 45', 'Taille 46', 'Taille 47', 'Taille 48']

export function slugify(text) {
  return text.toString()
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '-')
    .replace(/&/g, '-and-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
}

// Filter arrays with pre-built search terms
export function buildSearchTerms(...args) {
  return slugify(args.join(' '))
}

export function matchSearchTerms(terms, key) {
  return terms.reduce((found, term) => {
    return found && key.indexOf(term) !== -1
  }, true)
}

export function filterByQuery(array, query, searchTermsKey = '_searchKey') {
  if (query) {
    let searchTerms = query.split(' ').map(term => buildSearchTerms(term))

    return array.filter(employee => (
      matchSearchTerms(searchTerms, employee[searchTermsKey] || '')
    ))
  }
  else {
    return array
  }
}

export function prepareStockArticles(articles) {
  const sortedArticles = sortBy(articles.map(article => {
    return {
      ...article,
      _sortKey: [article.categorySort, article.name, article.description, getSizeSortKey(article)].join()
    }
  }), '_sortKey')
  let groupedArticles = []

  sortedArticles.forEach(article => {
    if (article.size) {
      const previousGroupedArticle = groupedArticles[groupedArticles.length - 1]
      if (previousGroupedArticle && previousGroupedArticle.name === article.name) {
        previousGroupedArticle.articles.push(article)
      }
      else {
        groupedArticles.push({
          id: article.id,
          name: article.name,
          description: article.description,
          articles: [article],
          category: article.category,
          sites: article.sites
        })
      }
    }
    else {
      groupedArticles.push({ ...article, quantity: 0 })
    }
  })

  return groupedArticles
}

export function prepareValuationArticles(articles) {
  return sortBy(articles
    .filter(article => !article.isExternalStock)
    .map(article => {
      return {
        ...article,
        _sortKey: [article.name, article.description, getSizeSortKey(article)].join()
      }
    }), '_sortKey')
}

// export function groupArticlesByName(articles) {
//   let groupedArticles = []
//   articles.forEach(article => {
//     if (article.size) {
//       const previousGroupedArticle = groupedArticles[groupedArticles.length - 1]
//       if (previousGroupedArticle && previousGroupedArticle.name === article.name) {
//         previousGroupedArticle.articles.push(article)
//       }
//       else {
//         groupedArticles.push({
//           id: article.id,
//           name: article.name,
//           description: article.description,
//           articles: [article],
//           category: article.category,
//           sites: article.sites
//         })
//       }
//     }
//     else {
//       groupedArticles.push({ ...article, quantity: 0 })
//     }
//   })
//   return groupedArticles
// }

export function sortByName(articles) {
  return sortBy(articles, 'name')
}

export function getSizeSortKey(article) {
  if (article.size) {
    const { size } = article
    if (TEE_SHIRT_SIZES.includes(size)) {
      return TEE_SHIRT_SIZES.indexOf(size)
    }
    else if (VESTE_SIZES.includes(size)) {
      return VESTE_SIZES.indexOf(size)
    }
    else {
      return CHAUSSURE_SIZES.indexOf(size)
    }
  }
  else {
    return 0
  }
}

// export function groupArticlesByCategory(articles) {
//   const groupedArticles = groupBy(articles, a => a.category || 'Autre')
//   return Object.keys(groupedArticles).map(key => ({
//     name: key,
//     articles: sortByName(groupedArticles[key])
//   }))
// }

// export function ungroupArticles(articles) {
//   return flattenDeep(articles.map(a => a.articles))
// }

export function getStockFromOperationGroups(operationGroups) {
  let articlesById = {}
  sortBy(operationGroups, 'id')
    .filter(({ kind }) => ['delivery', 'restitution', 'lost'].includes(kind))
    .forEach(operationGroup => {
      operationGroup.operations.forEach(operation => {
        const { article } = operation
        if (!articlesById[article.id]) {
          articlesById[article.id] = { ...article, quantityAvailable: -operation.quantityDiff }
        }
        else {
          articlesById[article.id].quantityAvailable -= operation.quantityDiff
        }
        switch (article.name) {
          case 'Carte AS24':
            articlesById[article.id].cards = articlesById[article.id].cards || []
            if (operationGroup.kind === 'delivery') {
              articlesById[article.id].cards.push(operationGroup.card)
            }
            else {
              articlesById[article.id].cards = articlesById[article.id].cards.filter(card => card.id !== operationGroup.card.id)
            }
            break
          case 'Téléphone':
            articlesById[article.id].phones = articlesById[article.id].phones || []
            if (operationGroup.kind === 'delivery') {
              articlesById[article.id].phones.push(operationGroup.phone)
            }
            else {
              articlesById[article.id].phones = articlesById[article.id].phones.filter(phone => phone.id !== operationGroup.phone.id)
            }
            break
          case 'Badge Harnes':
          case 'Badge Lille':
          case 'Badge Vitrolles':
            articlesById[article.id].badges = articlesById[article.id].badges || []
            if (operationGroup.kind === 'delivery') {
              articlesById[article.id].badges.push(operationGroup.badge)
            }
            else {
              articlesById[article.id].badges = articlesById[article.id].badges.filter(badge => badge.id !== operationGroup.badge.id)
            }
            break
        }
      })
    })
  return sortByName(Object.values(articlesById).filter(article => !!article.quantityAvailable))
}

export function getSizesFromOperationGroups(operationGroups) {
  let teeShirtSize = 'Taille M'
  let vesteSize = 'Taille 3'

  if (operationGroups && operationGroups.length) {
    operationGroups.forEach(operationGroup => {
      operationGroup.operations.forEach(operation => {
        const { article } = operation
        if (article.size && article.name === 'T-Shirt') {
          teeShirtSize = article.size
        }
        else if (article.size && article.name === 'Veste') {
          vesteSize = article.size
        }
      })
    })
  }

  return { teeShirtSize, vesteSize }
}

export function getEmployeesFromOperationGroups(operationGroups) {
  let quantityByEmployee = {}
  operationGroups
    .filter(({ kind }) => ['delivery', 'restitution', 'lost'].includes(kind))
    .forEach(operationGroup => {
      const { employee } = operationGroup
      operationGroup.operations.forEach(operation => {
        if (!quantityByEmployee[employee.id]) {
          quantityByEmployee[employee.id] = { ...employee, quantityAvailable: -operation.quantityDiff }
        }
        else {
          quantityByEmployee[employee.id].quantityAvailable -= operation.quantityDiff
        }
      })
    })
  return sortBy(
    Object.values(quantityByEmployee).filter(article => !!article.quantityAvailable),
    ['site', 'fullName']
  )
}

export function getArticlesFromOperationGroups(operationGroups) {
  let articlesById = {}
  sortBy(operationGroups, 'id')
    .filter(({ kind }) => ['delivery', 'restitution', 'lost'].includes(kind))
    .forEach(operationGroup => {
      operationGroup.operations.forEach(operation => {
        const { article } = operation
        const isDelivery = operationGroup.kind === 'delivery'
        const isRestitution = operationGroup.kind === 'restitution'
        const isLost = operationGroup.kind === 'lost'
        const quantity = Math.abs(operation.quantityDiff)
        if (!articlesById[article.id]) {
          articlesById[article.id] = {
            ...article,
            operationDate: operationGroup.operationDate
          }
        }

        if (isDelivery) {
          articlesById[article.id].quantityDelivery = articlesById[article.id].quantityDelivery || 0
          articlesById[article.id].quantityDelivery += quantity
          if (operationGroup.operationDate > articlesById[article.id].operationDate) {
            articlesById[article.id].operationDate = operationGroup.operationDate
          }
        }
        else if (isRestitution) {
          articlesById[article.id].quantityRestitution = articlesById[article.id].quantityRestitution || 0
          articlesById[article.id].quantityRestitution += quantity
        }
        else if (isLost) {
          articlesById[article.id].costLost = articlesById[article.id].costLost || 0
          articlesById[article.id].costLost += quantity * articlesById[article.id].costPublic
        }

        switch (article.name) {
          case 'Carte AS24':
            articlesById[article.id].identifier = operationGroup.card && operationGroup.card.identifier
            break
          case 'Téléphone':
            articlesById[article.id].identifier = operationGroup.phone && operationGroup.phone.identifier
            break
          case 'Badge Harnes':
          case 'Badge Lille':
          case 'Badge Vitrolles':
            articlesById[article.id].identifier = operationGroup.badge && operationGroup.badge.identifier
            break
        }
      })
    })
  return sortBy(Object.values(articlesById), 'name')
}

export function getLatestDateFromOperationGroups(operationGroups, kind, defaultNow) {
  if (operationGroups.length) {
    const operationGroup = sortBy(operationGroups, 'operationDate')
      .reverse()
      .find(o => o.kind === kind)
    if (operationGroup) {
      return operationGroup.operationDate
    }
  }
  return defaultNow ? new Date() : null
}
