<template>
  <div v-if="!$route.params.id || employee">
    <h1>
      <template v-if="employee">
        <router-link :to="{name: 'employees'}">Salariés</router-link>
        &nbsp;>&nbsp;
        <router-link :to="{name: 'employee', params: {id: employee.id}}">{{employee.fullName}}</router-link>
        &nbsp;>&nbsp;Modifier
      </template>
      <template v-else>
        Ajouter un salarié
      </template>
    </h1>
    <form class="mf-container" @submit.prevent="submit">
      <div class="flex mb-4 items-center">
        <div class="flex-grow text-gray-700">
          <template v-if="employee">
            Modifiez les informations ci-dessous puis validez les changements.
          </template>
          <template v-else>
            Remplissez le formulaire ci-dessous puis cliquez sur ajouter.
          </template>
        </div>
        <div class="flex-shrink-0">
          <button
            v-if="employee && !employee.isDeleted"
            type="button"
            tag="button"
            @click="removeEmployee"
            class="mf-destructive-button mr-12">
            Supprimer
          </button>
          <button
            type="button"
            tag="button"
            @click="$router.go(-1)"
            class="mf-secondary-button mr-2">
            Annuler
          </button>
          <button class="mf-primary-button">
            <template v-if="employee">
              {{isLoading ? 'Modification en cours…' : 'Modifier' }}
            </template>
            <template v-else>
              {{isLoading ? 'Ajout en cours…' : 'Ajouter' }}
            </template>
          </button>
        </div>
      </div>
      <div>
        <div class="flex flex-wrap -mx-3 mb-4">
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label ref="lastName" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="last-name">
              NOM
            </label>
            <input ref="lastName" v-model="employeeModel.lastName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="last-name" type="text" placeholder="Dupont" autocomplete="no">
          </div>
          <div class="w-full md:w-1/3 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="first-name">
              Prénom
            </label>
            <input v-model="employeeModel.firstName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="first-name" type="text" placeholder="Michel" autocomplete="no">
          </div>
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
              Site
            </label>
            <div class="relative">
              <select v-model="employeeModel.site" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                <option>Harnes</option>
                <option>Lille</option>
                <option>Plessis</option>
                <option>Vitrolles</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { validateInputs } from '../utils/operations'

export default {
  data() {
    return {
      isLoading: false,
      employee: null,
      employeeModel: {
        firstName: null,
        lastName: null,
        site: 'Harnes'
      }
    }
  },
  methods: {
    async submit() {
      if (validateInputs(this.employeeModel.firstName, this.employeeModel.lastName)) {
        this.isLoading = true
        const employee = await this.$store.dispatch(this.employee ? 'employees/updateEmployee' : 'employees/createEmployee', this.employeeModel)
        this.isLoading = false
        this.$router.push({ name: 'employee', params: { id: employee.id } })
      }
      else {
        alert('Veuillez remplir tous les éléments du formulaire.')
      }
    },
    async removeEmployee() {
      if (window.confirm('La suppression fera disparaître le salarié de la liste.\nIl apparaîtra toujours dans l’historique des mouvements.\nAssurez-vous que le salarié n’ait aucun article en sa possession avant de le supprimer !\n\nSouhaitez-vous continuer ?')) {
        await this.$store.dispatch('employees/removeEmployee', this.employeeModel)
        alert('La suppression a bien été effectuée !')
        this.$router.push({ name: 'employees' })
      }
    }
  },
  mounted() {
    if (this.$refs.lastName) {
      this.$refs.lastName.focus()
    }
  },
  async created() {
    const { id } = this.$route.params
    if (id) {
      try {
        this.employee = await this.$store.dispatch('employees/getEmployee', id)
        this.employeeModel = { ...this.employee }
      }
      catch (e) {
        this.$router.push({ name: 'employees' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
