<template>
  <div>
    <table class="table-fixed w-full mb-3" v-if="filteredCards && filteredCards.length">
      <thead>
        <tr>
          <th>Numéro</th>
          <th>Validité</th>
          <th class="w-1/2">Salarié</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="card in filteredCards" :key="card.id"
          class="odd:bg-gray-100 hover:bg-indigo-100"
          :class="{
            'cursor-pointer': !isNested,
            'mf-highlighted': $route.params.cardId === card.id,
            'mf-lost': card.isLost
          }"
          @click="$router.push({name: 'card', params: {id: card.id}})">
          <td class="mf-tnum border px-4 py-1 whitespace-no-wrap">
            {{card.identifier}}
          </td>
          <td class="border px-4 py-1 whitespace-no-wrap">
            {{card.validity}}
          </td>
          <td class="border px-4 py-1 whitespace-no-wrap">
            {{card.employee ? card.employee.fullName : '…'}}
          </td>
        </tr>
      </tbody>
    </table>
    <p class="text-gray-600" v-else>
      Aucune carte.
    </p>
  </div>
</template>

<script>
export default {
  props: {
    isNested: Boolean,
    cards: Array
  },
  computed: {
    filteredCards() {
      return this.cards
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
