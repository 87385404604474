import Vue from '@vue'
import Vuex from 'vuex'

import account from '@/store/modules/account'
import articles from '@/store/modules/articles'
import employees from '@/store/modules/employees'
import operations from '@/store/modules/operations'
import cards from '@/store/modules/cards'
import phones from '@/store/modules/phones'
import badges from '@/store/modules/badges'

Vue.use(Vuex)

Vue.filter('formatFloat', (number) => {
  if (!number) return ''
  return number.toLocaleString('fr-FR')
})

export default new Vuex.Store({
  modules: {
    account,
    articles,
    employees,
    operations,
    cards,
    phones,
    badges
  }
})
