<template>
  <div>
    <div class="flex items-center pb-4">
      <h1 class="flex-grow pb-0">Badges</h1>
      <router-link
        tag="button"
        class="mf-primary-button ml-3"
        :to="{name: 'createBadge'}">
        Ajouter un badge
      </router-link>
    </div>
    <div class="mf-container" v-if="badges.length || searchQuery">
      <div class="float-right">
        <input
          class="appearance-none border border-blue-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-300 text-left"
          type="text"
          placeholder="Rechercher un badge…"
          v-model="searchQuery"
          @input="loadBadges">
      </div>
      <div v-for="group in badges" :key="group.key">
        <h2>{{group.key}}</h2>
        <badges class="mb-5" :badges="group.badges" />
      </div>
      <p v-if="!badges.length" class="text-gray-600 pt-2">Aucun badge trouvé.</p>
      <div v-if="filterQuery == 'default' && !searchQuery" class="text-gray-600">
        <hr class="mt-2 mb-4">
        Les badges perdus ou usés ne sont pas affichés.
        <button class="mf-secondary-button leading-normal ml-2" @click="loadAll">
          Afficher tout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Badges from '@components/Badges'
import groupBy from 'lodash.groupby'

function groupBadgesBySite(badges) {
  const DEFAULT_GROUP = 'À distribuer'
  const groupedBadges = groupBy(badges, b => b.employee ? b.site : DEFAULT_GROUP)
  // Terrible hack to move DEFAULT_GROUP to bottom
  if (groupedBadges[DEFAULT_GROUP]) {
    const value = groupedBadges[DEFAULT_GROUP]
    delete groupedBadges[DEFAULT_GROUP]
    groupedBadges[DEFAULT_GROUP] = value
  }
  return Object.keys(groupedBadges).map(key => ({
    key,
    badges: groupedBadges[key]
  }))
}

export default {
  components: { Badges },
  data() {
    return {
      badges: [],
      searchQuery: location.hash.substr(1) || null,
      filterQuery: 'default'
    }
  },
  methods: {
    async loadBadges() {
      const query = this.searchQuery
      const filter = this.filterQuery
      this.badges = groupBadgesBySite(await this.$store.dispatch('badges/getBadges', { query, filter }))
    },
    loadAll() {
      this.filterQuery = 'all'
      this.loadBadges()
    }
  },
  watch: {
    searchQuery() {
      if (history.pushState) {
        history.pushState(null, null, '#' + this.searchQuery)
      }
    }
  },
  async created() {
    this.loadBadges()
  }
}
</script>

<style lang="scss" scoped>
</style>
