import api from '@/store/api'
import { TEE_SHIRT_SIZES, VESTE_SIZES, CHAUSSURE_SIZES } from '@/utils/display'

const CATEGORIES = ['CDDI', 'Permanent', 'Harnes', 'Lille', 'Plessis', 'Vitrolles']
const SITES = ['Harnes', 'Lille', 'Plessis', 'Vitrolles']

const getters = {
  getTeeShirtSizes() {
    return TEE_SHIRT_SIZES
  },
  getVesteSizes() {
    return VESTE_SIZES
  },
  getChaussureSizes() {
    return CHAUSSURE_SIZES
  },
  getCategories() {
    return CATEGORIES
  },
  getSites() {
    return SITES
  }
}

const state = {
  articles: []
}

const actions = {
  getArticles(context) {
    return api.get('/articles')
      .then(({ data }) => {
        context.commit('setArticles', data)
        return data
      })
  },
  getCachedArticles(context) {
    const articles = context.state.articles
    if (articles.length) {
      return Promise.resolve(articles)
    }
    else {
      return context.dispatch('getArticles')
    }
  },
  getArticle(_context, id) {
    return api.get('/articles/' + id)
      .then(({ data }) => {
        return data
      })
  },
  createArticle(_context, article) {
    return api.post('/articles', article)
      .then(({ data }) => {
        return data
      })
  },
  updateArticle(_context, article) {
    return api.put('/articles/' + article.id, article)
      .then(({ data }) => {
        return data
      })
  },
  getArticleByName(context, name) {
    return context.dispatch('getCachedArticles').then(articles => {
      return articles.find(a => a.name === name)
    })
  },
  removeArticle(_context, article) {
    return api.delete('/articles/' + article.id)
      .then(({ data }) => {
        return data
      })
  }
}

const mutations = {
  setArticles(state, articles) {
    state.articles = articles
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
