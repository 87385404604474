<template>
  <div>
    <div class="flex items-center pb-4">
      <h1 class="flex-grow pb-0">Téléphones</h1>
      <router-link
        tag="button"
        class="mf-primary-button ml-3"
        :to="{name: 'createPhone'}">
        Ajouter un téléphone
      </router-link>
    </div>
    <div class="mf-container" v-if="phones.length || searchQuery">
      <div class="float-right">
        <input
          class="appearance-none border border-blue-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-300 text-left"
          type="text"
          placeholder="Rechercher un téléphone…"
          v-model="searchQuery"
          @input="loadPhones">
      </div>
      <div v-for="group in phones" :key="group.key">
        <h2>{{group.key}}</h2>
        <phones class="mb-5" :phones="group.phones" />
      </div>
      <p v-if="!phones.length" class="text-gray-600 pt-2">Aucun téléphone trouvé.</p>
      <div v-if="filterQuery == 'default' && !searchQuery" class="text-gray-600">
        <hr class="mt-2 mb-4">
        Les téléphones perdus ou usés ne sont pas affichés.
        <button class="mf-secondary-button leading-normal ml-2" @click="loadAll">
          Afficher tout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Phones from '@components/Phones'
import groupBy from 'lodash.groupby'

function groupPhonesBySite(phones) {
  const groupedPhones = groupBy(phones, c => c.employee ? c.employee.site : 'À distribuer')
  return Object.keys(groupedPhones).map(key => ({
    key,
    phones: groupedPhones[key]
  }))
}

export default {
  components: { Phones },
  data() {
    return {
      phones: [],
      searchQuery: location.hash.substr(1) || null,
      filterQuery: 'default'
    }
  },
  methods: {
    async loadPhones() {
      const query = this.searchQuery
      const filter = this.filterQuery
      this.phones = groupPhonesBySite(await this.$store.dispatch('phones/getPhones', { query, filter }))
    },
    loadAll() {
      this.filterQuery = 'all'
      this.loadPhones()
    }
  },
  watch: {
    searchQuery() {
      if (history.pushState) {
        history.pushState(null, null, '#' + this.searchQuery)
      }
    }
  },
  async created() {
    this.loadPhones()
  }
}
</script>

<style lang="scss" scoped>
</style>
