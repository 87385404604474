<template>
  <div class="mf-container" v-if="employee">
    <header class="flex justify-between items-center">
      <div class="w-32">
        <img src="~@images/main-forte-logo.jpg" class="h-16">
      </div>
      <div class=" text-center">
        <h1 class="p-0">Prise en compte du matériel</h1>
      </div>
      <div class="w-32 text-right text-gray-600"></div>
    </header>
    <p class="px-4 py-2 font-bold">
      Nom du chauffeur : {{employee.fullName}}
    </p>
    <div>
      <table class="table-auto w-full mb-3">
        <thead>
          <tr>
            <th class="text-center">Date de remise</th>
            <th class="text-center">Quantité prêtée</th>
            <th class="w-1/2">Article</th>
            <th class="text-center">Coût unitaire</th>
            <th class="text-center">Quantité restituée</th>
            <th class="text-center">Retenue</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="article in articles" :key="article.id">
            <td class="text-center">{{formatDate(article.operationDate, 'dd/MM/yyyy')}}</td>
            <td class="text-center">{{article.quantityDelivery}}</td>
            <td>
              {{article.name}}
              <template v-if="article.description">
                ({{article.description}})
              </template>
              <template v-if="article.identifier">
                &nbsp;{{article.identifier}}
              </template>
              <template v-if="article.size">
                <br>
                {{article.size}}
              </template>
            </td>
            <td class="text-center">{{article.costPublic | formatFloat}} €</td>
            <td class="text-center">{{article.quantityRestitution || article.costLost ? article.quantityRestitution || 0 : ''}}</td>
            <td class="text-center">
              <template v-if="article.costLost">
                {{article.costLost | formatFloat}} €
              </template>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="totalCostLost">
          <tr>
            <td colspan="4" class="border-none"></td>
            <td class="footer text-center">Coût total</td>
            <td class="footer text-center">{{totalCostLost | formatFloat}} €</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <footer>
      <div class="text-sm mt-6">
        <strong class="underline">Attribution personnelle de matériel :</strong>
        <p> Je reconnais que la société Main Forte m’a confié ce jour et jusqu'à
  la fin de mes relations contractuelles avec cette société une carte AS24. Je
  suis informé et conscient que cette carte constitue un outil de travail et
  qu’il doit exclusivement être utilisé comme tel.</p>
        <p>J’ai également été informé qu’en cas de perte, de vol, de
  dégradation de ce matériel, il me sera déduit de la fiche de paie les sommes
  inscrites sur ce document, signé par mes soins. De plus, en cas
  d’utilisation frauduleuse, toutes les dépenses sont dues et une procédure de
  sanction sera engagée.</p>
      </div>
      <div class="flex mt-6 mb-2">
        <div class="w-1/2 pt-1 pb-8">
          <strong>Date de la remise : {{deliveryDate}}</strong><br>
          Signature du chauffeur :
        </div>
        <div class="w-1/2 border-l border-gray-900 pl-4 pt-1 pb-8">
          <strong>Date de la restitution : {{restitutionDate}}</strong><br>
          Signature Main Forte :
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { formatDate, formatToday } from '@utils/format'
import { getArticlesFromOperationGroups, getLatestDateFromOperationGroups } from '@utils/display'

export default {
  props: {
    employee: Object
  },
  computed: {
    operationGroups() {
      return this.employee ? this.employee.operationGroups : []
    },
    articles() {
      return getArticlesFromOperationGroups(this.operationGroups)
    },
    deliveryDate() {
      const deliveryDate = getLatestDateFromOperationGroups(this.operationGroups, 'delivery', true)
      return deliveryDate ? formatDate(deliveryDate, 'd MMMM yyyy') : ''
    },
    restitutionDate() {
      const restitutionDate = getLatestDateFromOperationGroups(this.operationGroups, 'restitution')
      return restitutionDate ? formatDate(restitutionDate, 'd MMMM yyyy') : ''
    },
    totalCostLost() {
      return this.articles.reduce((memo, article) => {
        memo += article.costLost || 0
        return memo
      }, 0)
    }
  },
  methods: {
    formatDate,
    formatToday
  }
}
</script>

<style lang="scss" scoped>
th, .footer {
  @apply text-sm bg-gray-200 border border-black py-1 text-black font-semibold;
}

td {
  @apply text-sm border border-black px-2 py-0;
}
</style>
