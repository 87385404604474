/* global ExcelJS */
import { saveAs } from 'file-saver'

function adjustColumnsWidth(sheet) {
  // https://github.com/exceljs/exceljs/issues/83#issuecomment-469120359
  const pixelsPerExcelWidthUnit = 6.5
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const maxColumnLengths = {}
  sheet.eachRow((row, rowNum) => {
    row.eachCell((cell, num) => {
      if (typeof cell.value === 'string') {
        if (!maxColumnLengths[num]) {
          maxColumnLengths[num] = 0
        }
        ctx.font = `11pt Calibri`
        const metrics = ctx.measureText(cell.value)
        const cellWidth = metrics.width

        maxColumnLengths[num] = Math.max(maxColumnLengths[num], cellWidth)
      }
    })
  })
  for (let i = 1; i <= sheet.columnCount; i++) {
    const col = sheet.getColumn(i)
    const width = maxColumnLengths[i]
    col.width = width / pixelsPerExcelWidthUnit + 1
  }
}

function styleCells(sheet) {
  for (let i = 1; i <= sheet.columnCount; i++) {
    // Header background color
    sheet.getRow(1).getCell(i).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFEDF8F7' }
    }

    for (let j = 1; j <= sheet.rowCount; j++) {
      let cell = sheet.getRow(j).getCell(i)
      // Cell borders
      cell.border = {
        top: { style: 'thin', color: { argb: 'FFD7E4E2' } },
        left: { style: 'thin', color: { argb: 'FFD7E4E2' } },
        bottom: { style: 'thin', color: { argb: 'FFD7E4E2' } },
        right: { style: 'thin', color: { argb: 'FFD7E4E2' } }
      }
      // Date format
      if (cell.value instanceof Date) {
        cell.numFmt = 'dd/mm/yyyy'
      }
    }
  }
}

export function downloadExcel(rows) {
  const filename = 'valorisation-du-stock.xlsx'
  console.log(ExcelJS)
  const wb = new ExcelJS.Workbook()
  const ws = wb.addWorksheet('Stock')

  // Generate header
  const header = ws.addRow(rows.shift())
  header.font = { bold: true }

  // Generate content
  rows.forEach(row => {
    ws.addRow(row)
  })

  // Setup style
  adjustColumnsWidth(ws)
  styleCells(ws)

  // Generate and download file
  return wb.xlsx.writeBuffer().then(buf => {
    saveAs(new Blob([buf]), filename)
    return filename
  })
}
