<template>
  <div v-if="!$route.params.id || phone">
    <h1>
      <template v-if="phone">
        <router-link :to="{name: 'phones'}">Téléphones</router-link>
        &nbsp;>&nbsp;
        <router-link :to="{name: 'phone', params: {id: phone.id}}">{{phone.identifier}}</router-link>
        &nbsp;>&nbsp;Modifier
      </template>
      <template v-else>
        Ajouter un téléphone
      </template>
    </h1>
    <form class="mf-container" @submit.prevent="submit">
      <div class="flex mb-4 items-center">
        <div class="flex-grow text-gray-700">
          <template v-if="phone">
            Modifiez les informations ci-dessous puis validez les changements.
          </template>
          <template v-else>
            Remplissez le formulaire ci-dessous puis cliquez sur ajouter.
          </template>
        </div>
        <div class="flex-shrink-0">
          <button
            v-if="phone"
            type="button"
            tag="button"
            @click="removePhone"
            class="mf-destructive-button mr-2">
            Supprimer
          </button>
          <button
            v-if="phone"
            type="button"
            tag="button"
            @click="toggleIsLost"
            class="mf-destructive-button mr-12">
            Perdu / Usé
          </button>
          <button
            type="button"
            tag="button"
            @click="$router.go(-1)"
            class="mf-secondary-button mr-2">
            Annuler
          </button>
          <button class="mf-primary-button">
            <template v-if="phone">
              {{isLoading ? 'Modification en cours…' : 'Modifier' }}
            </template>
            <template v-else>
              {{isLoading ? 'Ajout en cours…' : 'Ajouter' }}
            </template>
          </button>
        </div>
      </div>
      <div>
        <div class="flex flex-wrap -mx-3">
          <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Numéro
            </label>
            <input
              ref="identifier"
              v-model="phoneModel.identifier"
              class="appearance-none block w-full  text-gray-700 border rounded py-3 mb-3 leading-tight focus:outline-none"
              :class="phone ? 'bg-white border-white' : 'px-4 bg-gray-200 border-gray-200 focus:bg-white focus:border-gray-500'"
              type="text"
              placeholder="0674135408"
              autocomplete="no"
              :readonly="phone">
          </div>
          <div class="w-full md:w-1/4 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Code Pin
            </label>
            <input v-model="phoneModel.codePin" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" >
          </div>
          <div class="w-full md:w-1/4 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Code Puk
            </label>
            <input v-model="phoneModel.codePuk" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" >
          </div>
          <div class="w-full md:w-1/4 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Type
            </label>
            <div class="relative">
              <select
                v-model="phoneModel.subscription"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                <option>Tel Permanent</option>
                <option>Tel VL</option>
                <option>Tel PL</option>
                <option>Tel SPL</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { validateInputs } from '../utils/operations'

export default {
  data() {
    return {
      isLoading: false,
      phone: null,
      phoneModel: {
        identifier: null,
        codePin: null,
        codePuk: null,
        subscription: 'Tel Permanent',
        status: 'Neuf'
      }
    }
  },
  methods: {
    async submit() {
      if (validateInputs(this.phoneModel.identifier, this.phoneModel.codePin, this.phoneModel.codePuk)) {
        this.isLoading = true
        const phone = await this.$store.dispatch(this.phone ? 'phones/updatePhone' : 'phones/createPhone', this.phoneModel)
        this.isLoading = false
        this.$router.push({ name: phone ? 'phone' : 'phones', params: { phoneId: phone.id, id: phone.id } })
      }
      else {
        alert('Veuillez remplir tous les éléments du formulaire.')
      }
    },
    async removePhone() {
      if (window.confirm('La suppression du téléphone le fera disparaître de la liste.\nIl apparaîtra toujours dans l’historique des mouvements.\n\nSouhaitez-vous continuer ?')) {
        await this.$store.dispatch('phones/removePhone', this.phoneModel)
        alert('La suppression a bien été effectuée !')
        this.$router.push({ name: 'phones' })
      }
    },
    async toggleIsLost() {
      const lostLabel = this.phone.isLost ? 'apparaître' : 'disparaître'
      if (window.confirm(`Cette déclaration fera ${lostLabel} l’article de la liste des articles distribuables.\n\nSouhaitez-vous continuer ?`)) {
        const phoneModel = { ...this.phone, isLost: !this.phone.isLost }
        const phone = await this.$store.dispatch('phones/updatePhone', phoneModel)
        alert('La déclaration a bien été prise en compte !')
        this.$router.push({ name: 'phone', params: { id: phone.id } })
      }
    }
  },
  mounted() {
    if (this.$refs.identifier) {
      this.$refs.identifier.focus()
    }
  },
  async created() {
    const { id } = this.$route.params
    if (id) {
      try {
        this.phone = await this.$store.dispatch('phones/getPhone', id)
        this.phoneModel = { ...this.phone }
      }
      catch (e) {
        this.$router.push({ name: 'phones' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
