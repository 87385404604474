<template>
  <div class="mb-6">
    <input
      class="appearance-none border rounded-t w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-300 text-left"
      type="text"
      placeholder="Rechercher un salarié…"
      v-model="searchQuery">
    <div v-if="filteredEmployees.length" style="max-height: 206px" class="overflow-y-auto">
      <employees
        class="overflow-y-auto"
        :isSelectable="true"
        :employees="filteredEmployees"
        v-model="employeeModel"
        @input="select" />
    </div>
    <p class="text-gray-600 mt-2" v-else-if="employees.length">
      Aucun salarié trouvé.<br>
      Pour ajouter un salarié,
      <router-link :to="{name: 'createEmployee'}">cliquez ici</router-link>.
    </p>
  </div>
</template>

<script>
import Employees from '@components/Employees'
import { buildSearchTerms, filterByQuery } from '@/utils/display'

export default {
  components: { Employees },
  props: {
    value: Object
  },
  data() {
    return {
      searchQuery: null,
      employeeModel: this.value,
      employees: []
    }
  },
  watch: {
    value() {
      this.employeeModel = this.value
    }
  },
  computed: {
    enrichedEmployees() {
      return this.employees.map(e => ({ ...e, _searchKey: buildSearchTerms(e.firstName, e.lastName) }))
    },
    filteredEmployees() {
      const employees = filterByQuery(this.enrichedEmployees, this.searchQuery)
      return employees
    }
  },
  methods: {
    select(employee) {
      this.employeeModel = employee
      this.$emit('input', employee)
    }
  },
  async mounted() {
    this.employees = await this.$store.dispatch('employees/getEmployees')
    if (this.$route.params.employeeId) {
      const selectedEmployee = this.employees.find(e => e.id === this.$route.params.employeeId)
      if (selectedEmployee) {
        this.select(selectedEmployee)
        this.searchQuery = selectedEmployee.fullName
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
