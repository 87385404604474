<template>
  <div>
    <h1>Formulaire de remise</h1>
    <div class="mf-container">
      <div class="flex mb-4 items-center">
        <div class="flex-grow text-gray-700">
          Remplissez le formulaire puis cliquez sur les articles pour les ajouter.<br>
          Vous pourrez imprimer la fiche de prise en compte après la validation.
        </div>
        <div class="flex-shrink-0">
          <button
            type="button"
            tag="button"
            @click="$router.go(-1)"
            class="mf-secondary-button mr-2">
            Annuler
          </button>
          <button
            class="mf-primary-button"
            @click="submit">
            {{isLoading ? 'Validation en cours…' : 'Valider la remise' }}
          </button>
        </div>
      </div>
      <div class="md:flex">
        <div class="flex-1">
          <h2 class="inline-block">Salarié</h2>
          <div>
            <employee-picker v-if="articles.length" v-model="model.employee" @input="onEmployeeChange" />
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Tee-shirt
                <!-- <label class="uppercase tracking-wide text-gray-500 text-xs font-bold ml-1">
                  Optionnel
                </label> -->
              </label>
              <div class="relative">
                <select
                  v-model="teeShirtSize"
                  @change="prefillArticles"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  <option v-for="size in teeShirtSizes" :key="size">{{size}}</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Veste
                <!-- <label class="uppercase tracking-wide text-gray-500 text-xs font-bold ml-1">
                  Optionnel
                </label> -->
              </label>
              <div class="relative">
                <select
                  v-model="vesteSize"
                  @change="prefillArticles"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  <option v-for="size in vesteSizes" :key="size">{{size}}</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Chaussures
                <!-- <label class="uppercase tracking-wide text-gray-500 text-xs font-bold ml-1">
                  Optionnel
                </label> -->
              </label>
              <div class="relative">
                <select
                  v-model="chaussureSize"
                  @change="prefillArticles"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  <option v-for="size in chaussureSizes" :key="size">{{size}}</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
                Site
              </label>
              <div class="relative">
                <select
                  v-model="model.site"
                  @change="prefillArticles"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state">
                  <option value="" selected disabled>Choisir un site</option>
                  <option>Harnes</option>
                  <option>Lille</option>
                  <option>Plessis</option>
                  <option>Vitrolles</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-zip">
                Date de remise
              </label>
              <input v-model="model.operationDate" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 pl-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-zip" type="date" placeholder="2/11/2019">
            </div>
          </div>
          <div>
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="last-name">
              Note interne
            </label>
            <textarea
              v-model="model.internalNote"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
              placeholder="Cette note s’affichera uniquement dans l’outil."
              rows="2"></textarea>
          </div>
        </div>
        <div class="flex-1 pl-12">
          <div class="flex">
            <h2 class="inline-block mr-2 flex-grow">Articles distribués</h2>
            <button
              class="mf-secondary-button flex-shrink-0 leading-normal py-0 h-6 mt-1"
              v-if="selectedArticles.length"
              @click="resetArticles">
              Tout enlever
            </button>
          </div>
          <articles-picker
            :articles="filteredArticles"
            :availableCards="availableCards"
            :availablePhones="availablePhones"
            :availableBadges="availableBadges"
            @setCard="setCard"
            @setPhone="setPhone"
            @setBadge="setBadge"
            @change="change" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticlesPicker from '@components/ArticlesPicker'
import EmployeePicker from '@components/EmployeePicker'
import { formatToday } from '@utils/format'
import {
  prepareArticles,
  filterSelectedArticles,
  validateInputs,
  filterArticleBySizes,
  prefillArticles,
  updateArticles,
  validateInputUnicity,
  validateSpecialInput
} from '@utils/operations'

export default {
  components: { ArticlesPicker, EmployeePicker },
  data() {
    return {
      articles: [],
      availableCards: [],
      availablePhones: [],
      availableBadges: [],
      model: {},
      teeShirtSize: 'Taille M',
      vesteSize: 'Taille 3',
      chaussureSize: 'Taille 40',
      isLoading: false,
      isNewEmployee: false
    }
  },
  computed: {
    teeShirtSizes() {
      return this.$store.getters['articles/getTeeShirtSizes']
    },
    vesteSizes() {
      return this.$store.getters['articles/getVesteSizes']
    },
    chaussureSizes() {
      return this.$store.getters['articles/getChaussureSizes']
    },
    filteredArticles() {
      const sizes = [this.teeShirtSize, this.vesteSize, this.chaussureSize]
      return filterArticleBySizes(this.articles, sizes)
    },
    selectedArticles() {
      return filterSelectedArticles(this.articles)
    }
  },
  methods: {
    change({ id, quantity }) {
      this.articles = updateArticles(this.articles, id, quantity)
    },
    setCard(cardId) {
      this.model.cardId = cardId
    },
    setPhone(phoneId) {
      this.model.phoneId = phoneId
    },
    setBadge(badgeId) {
      this.model.badgeId = badgeId
    },
    initModel() {
      this.model = {
        kind: 'delivery',
        site: '',
        internalNote: null,
        operationDate: formatToday(),
        employee: {},
        cardId: null,
        phoneId: null,
        badgeId: null
      }
    },
    onEmployeeChange() {
      this.model.site = this.model.employee.site
      this.$store.dispatch('employees/getEmployeeSizes', this.model.employee.id).then(({ teeShirtSize, vesteSize }) => {
        this.teeShirtSize = teeShirtSize
        this.vesteSize = vesteSize
        this.prefillArticles()
      })
    },
    prefillArticles() {
      const sizes = [this.teeShirtSize, this.vesteSize, this.chaussureSize]
      this.articles = prefillArticles(this.articles, this.model.site, sizes)
    },
    resetArticles() {
      this.articles.forEach(article => {
        article.quantity = 0
      })
    },
    async submit() {
      const articles = this.selectedArticles
      if (validateInputs(this.model.employee.id) && articles.length) {
        if (!validateSpecialInput(this.selectedArticles, 'Carte AS24', this.model.cardId)) {
          alert('Veuillez choisir quelle carte AS24 distribuer.')
          return
        }
        if (!validateSpecialInput(this.selectedArticles, 'Téléphone', this.model.phoneId)) {
          alert('Veuillez choisir quel téléphone distribuer.')
          return
        }
        if (!validateSpecialInput(this.selectedArticles, 'Badge Harnes', this.model.badgeId) ||
            !validateSpecialInput(this.selectedArticles, 'Badge Lille', this.model.badgeId) ||
            !validateSpecialInput(this.selectedArticles, 'Badge Vitrolles', this.model.badgeId)) {
          alert('Veuillez choisir quel badge distribuer.')
          return
        }
        if (!validateInputUnicity(this.selectedArticles, ['Badge Harnes', 'Badge Lille', 'Badge Vitrolles'])) {
          alert('Veuillez sélectionner un seul badge à distribuer.')
          return
        }
        let model = { ...this.model, articles }
        this.isLoading = true
        const { id } = await this.$store.dispatch('operations/createOperationGroup', model)
        this.isLoading = false
        this.$router.push({ name: 'employee', params: { id: this.model.employee.id, isDeliverySuccess: true, operationGroupId: id } })
      }
      else {
        alert('Veuillez sélectionner un salarié et une liste d’articles.')
      }
    }
  },
  created() {
    this.initModel()
  },
  async mounted() {
    this.articles = prepareArticles(await this.$store.dispatch('articles/getArticles'))
    this.availableCards = await this.$store.dispatch('cards/getAvailableCards')
    this.availablePhones = await this.$store.dispatch('phones/getAvailablePhones')
    this.availableBadges = await this.$store.dispatch('badges/getAvailableBadges')
  }
}
</script>

<style lang="scss" scoped>
</style>
