<template>
  <div v-if="!$route.params.id || card">
    <h1>
      <template v-if="card">
        <router-link :to="{name: 'cards'}">Cartes AS24</router-link>
        &nbsp;>&nbsp;
        <router-link :to="{name: 'card', params: {id: card.id}}">{{card.identifier}}</router-link>
        &nbsp;>&nbsp;Modifier
      </template>
      <template v-else>
        Ajouter une carte AS24
      </template>
    </h1>
    <form class="mf-container" @submit.prevent="submit">
      <div class="flex mb-4 items-center">
        <div class="flex-grow text-gray-700">
          <template v-if="card">
            Modifiez les informations ci-dessous puis validez les changements.
          </template>
          <template v-else>
            Remplissez le formulaire ci-dessous puis cliquez sur ajouter.
          </template>
        </div>
        <div class="flex-shrink-0">
          <button
            v-if="card"
            type="button"
            tag="button"
            @click="removeCard"
            class="mf-destructive-button mr-2">
            Supprimer
          </button>
          <button
            v-if="card"
            type="button"
            tag="button"
            @click="toggleIsLost"
            class="mf-destructive-button mr-12">
            Perdu / Usé
          </button>
          <button
            type="button"
            tag="button"
            @click="$router.go(-1)"
            class="mf-secondary-button mr-2">
            Annuler
          </button>
          <button class="mf-primary-button">
            <template v-if="card">
              {{isLoading ? 'Modification en cours…' : 'Modifier' }}
            </template>
            <template v-else>
              {{isLoading ? 'Ajout en cours…' : 'Ajouter' }}
            </template>
          </button>
        </div>
      </div>
      <div>
        <div class="flex flex-wrap -mx-3">
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Numéro
            </label>
            <input
              ref="identifier"
              v-model="cardModel.identifier"
              class="appearance-none block w-full  text-gray-700 border rounded py-3 mb-3 leading-tight focus:outline-none"
              :class="card ? 'bg-white border-white' : 'px-4 bg-gray-200 border-gray-200 focus:bg-white focus:border-gray-500'"
              type="text"
              placeholder="2001"
              autocomplete="no"
              :readonly="card">
          </div>
          <div class="w-full md:w-1/3 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Code
            </label>
            <input v-model="cardModel.code" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="1234" autocomplete="no">
          </div>
          <div class="w-full md:w-1/3 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Validité
            </label>
            <input v-model="cardModel.validity" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="2022" autocomplete="no">
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { validateInputs } from '../utils/operations'

export default {
  data() {
    return {
      isLoading: false,
      card: null,
      cardModel: {
        identifier: null,
        code: null,
        validity: null,
        status: 'Neuf'
      }
    }
  },
  methods: {
    async submit() {
      if (validateInputs(this.cardModel.identifier, this.cardModel.code, this.cardModel.validity)) {
        this.isLoading = true
        const card = await this.$store.dispatch(this.card ? 'cards/updateCard' : 'cards/createCard', this.cardModel)
        this.isLoading = false
        this.$router.push({ name: this.card ? 'card' : 'cards', params: { cardId: card.id, id: card.id } })
      }
      else {
        alert('Veuillez remplir tous les éléments du formulaire.')
      }
    },
    async removeCard() {
      if (window.confirm('La suppression de la carte AS24 la fera disparaître de la liste.\nElle apparaîtra toujours dans l’historique des mouvements.\n\nSouhaitez-vous continuer ?')) {
        await this.$store.dispatch('cards/removeCard', this.cardModel)
        alert('La suppression a bien été effectuée !')
        this.$router.push({ name: 'cards' })
      }
    },
    async toggleIsLost() {
      const lostLabel = this.card.isLost ? 'apparaître' : 'disparaître'
      if (window.confirm(`Cette déclaration fera ${lostLabel} l’article de la liste des articles distribuables.\n\nSouhaitez-vous continuer ?`)) {
        const cardModel = { ...this.card, isLost: !this.card.isLost }
        const card = await this.$store.dispatch('cards/updateCard', cardModel)
        alert('La déclaration a bien été prise en compte !')
        this.$router.push({ name: 'card', params: { id: card.id } })
      }
    }
  },
  mounted() {
    if (this.$refs.identifier) {
      this.$refs.identifier.focus()
    }
  },
  async created() {
    const { id } = this.$route.params
    if (id) {
      try {
        this.card = await this.$store.dispatch('cards/getCard', id)
        this.cardModel = { ...this.card }
      }
      catch (e) {
        this.$router.push({ name: 'cards' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
