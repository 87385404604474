import api from '@/store/api'

const state = {
  isConnected: false
}

const getters = {
  isConnected(state) {
    return state.isConnected
  }
}

const actions = {
  getAccount(context) {
    if (!context.getters.isConnected) {
      return api.get('/account')
        .then(({ data }) => {
          const { isConnected } = data
          context.commit('setIsConnected', isConnected)
          return isConnected
        })
    }
    else {
      return Promise.resolve(true)
    }
  },
  signin(context, password) {
    return api.post('/account', { password })
      .then(({ data }) => {
        const { isConnected } = data
        context.commit('setIsConnected', isConnected)
        return isConnected
      })
  }
}

const mutations = {
  setIsConnected(state, isConnected) {
    state.isConnected = isConnected
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
