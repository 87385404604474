import api from '@/store/api'
import { buildQuery } from '@utils/format'

const getters = {
}

const actions = {
  getBadges(_context, params) {
    const query = buildQuery(params)
    return api.get('/badges' + query)
      .then(({ data }) => {
        return data
      })
  },
  getAvailableBadges(_context) {
    return api.get('/badges?available=true')
      .then(({ data }) => {
        return data
      })
  },
  getBadge(_context, id) {
    return api.get('/badges/' + id)
      .then(({ data }) => {
        return data
      })
  },
  createBadge(_context, badge) {
    return api.post('/badges', badge)
      .then(({ data }) => {
        return data
      })
  },
  updateBadge(_context, badge) {
    return api.put('/badges/' + badge.id, badge)
      .then(({ data }) => {
        return data
      })
  },
  removeBadge(_context, badge) {
    return api.delete('/badges/' + badge.id)
      .then(({ data }) => {
        return data
      })
  }
}

export default {
  namespaced: true,
  getters,
  actions
}
