import sortBy from 'lodash.sortby'

export function prepareArticles(articles) {
  return sortBy(articles.map(article => {
    return {
      ...article,
      quantity: 0,
      _sortKey: [article.categorySort, article.name].join()
    }
  }), '_sortKey')
}

export function isArticleSizeOK(article, sizes) {
  return !article.size || sizes.includes(article.size)
}

export function filterArticleBySizes(articles, sizes) {
  return articles.filter(article => isArticleSizeOK(article, sizes) || article.quantity)
}

export function updateArticles(articles, id, quantity) {
  return articles.map(article => {
    if (article.id === id) {
      if ((quantity <= article.quantityAvailable || article.isExternalStock) &&
        quantity >= 0) {
        article.quantity = quantity
      }
    }
    return article
  })
}

export function updateLostArticles(articles, id, quantity) {
  let lostArticle = articles.find(a => a.id === id)
  const quantityDiff = lostArticle.quantity - quantity
  if (quantityDiff > 0) {
    return articles.map(article => {
      if (article.id === id) {
        article.quantity = quantity
      }
      return article
    }).filter(a => a.quantity)
  }
  else {
    return articles
  }
}

export function synchronizeLostArticles(lostArticles, articles, id, quantity) {
  let article = articles.find(article => article.id === id)
  const quantityDiff = article.quantity - quantity
  if (article && quantityDiff > 0 && quantity < article.quantityAvailable) {
    let lostArticle = lostArticles.find(a => a.id === article.id)
    if (!lostArticle) {
      lostArticles.push({ ...article, quantity: quantityDiff })
    }
    else if (lostArticle.quantity + quantityDiff <= lostArticle.quantityAvailable) {
      lostArticle.quantity += quantityDiff
    }
  }
  return lostArticles
}

export function prefillArticles(articles, site, sizes) {
  articles.forEach(article => {
    if (site && isArticleSizeOK(article, sizes) && article.sites.includes(site)) {
      article.quantity = Math.min(1, article.isExternalStock ? 1 : article.quantityAvailable)
    }
    else {
      article.quantity = 0
    }
  })
  return articles
}

export function filterSelectedArticles(articles) {
  return articles.filter(a => !!a.quantity)
}

export function validateInputs(...args) {
  return args.reduce((memo, input) => {
    return memo && input && input.toString().length
  }, true)
}

export function validateSpecialInput(selectedArticles, name, value) {
  const article = selectedArticles.find(a => a.name === name)
  return !article || !!value
}

export function validateInputUnicity(selectedArticles, names) {
  const articles = selectedArticles.filter(a => names.includes(a.name))
  return articles.length < 2
}

export function validateSiteInput(articleModel, validSites) {
  const sites = articleModel.sites
  if (!sites.split) {
    return true
  }
  const splitSites = sites.split(',').map(site => site.trim())
  const isAllSitesValid = splitSites.reduce((memo, site) => {
    return memo && validSites.includes(site)
  }, true)
  if (!isAllSitesValid) {
    alert('Le format des sites est invalide, vérifiez qu’ils sont bie séparés par une virgule. Seuls Harnes, Lille, Plessis et Vitrolles sont autorisés.')
  }
  else {
    articleModel.sites = splitSites
  }
  return isAllSitesValid
}

export function validateRestitutionArticles(articles, selectedArticles, selectedLostArticles) {
  let result = true
  articles.forEach(article => {
    const selectedArticle = selectedArticles.find(a => a.id === article.id)
    const selectedLostArticle = selectedLostArticles.find(a => a.id === article.id)
    const quantity = (selectedArticle ? selectedArticle.quantity : 0) + (selectedLostArticle ? selectedLostArticle.quantity : 0)
    if (quantity > article.quantityAvailable) {
      alert('Le nombre de ' + article.name + ' restitué est supérieur au nombre en possession du salarié.')
      result = false
    }
  })
  // Check multiple special article of same type
  const selectedArticlesTypes = [selectedArticles, selectedLostArticles]
  selectedArticlesTypes.forEach(selection => {
    ['badge', 'card', 'phone'].forEach(specialArticle => {
      if (selection.filter(article => !!article[specialArticle]).length > 1) {
        alert('Désolé mais un seul article du même type (Badge, Carte AS24, Téléphone) peut être restitué (ou perdu) dans un même mouvement. Veuillez séparer la restitution en plusieurs mouvements.')
        result = false
      }
    })
  })
  return result
}
