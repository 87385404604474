<template>
  <div v-if="article">
    <div class="flex items-center pb-4">
      <h1 class="flex-grow pb-0">
        <router-link :to="{name: 'stock'}">Stock</router-link>
        &nbsp;>&nbsp;
        {{article.name}}<br>
      </h1>
      <router-link
        v-if="['Badge Harnes', 'Badge Lille', 'Badge Vitrolles'].includes(article.name)"
        tag="button"
        class="mf-secondary-button"
        :to="{name: 'badges'}">
        Voir le détail des badges
      </router-link>
      <router-link
        v-if="article.name === 'Carte AS24'"
        tag="button"
        class="mf-secondary-button"
        :to="{name: 'cards'}">
        Voir le détail des cartes AS24
      </router-link>
      <router-link
        v-if="article.name === 'Téléphone'"
        tag="button"
        class="mf-secondary-button"
        :to="{name: 'phones'}">
        Voir le détail des téléphones
      </router-link>
      <router-link
        v-if="!article.isDeleted"
        tag="button"
        class="mf-primary-button ml-3"
        :to="{name: 'editArticle', params: {id: article.id}}">
        Modifier
      </router-link>
    </div>
    <div class="mf-container">
      <div class="md:flex">
        <div class="mt-4 md:mt-0 flex-grow">
          <h2 class="pb-1 inline-block">
            {{article.name}}
            <span v-if="article.size" class="text-gray-500 ml-1">{{article.size}}</span>
            <span v-if="article.isDeleted" class="text-red-400 ml-2">SUPPRIMÉ</span>
          </h2>
          <div class="text-gray-600" v-if="article.description">
            {{capitalize(article.description)}}
          </div>
          <div class="text-gray-600" v-if="!article.isReturnable || article.isExternalStock">
            <template v-if="!article.isReturnable">
              Cet article n’a pas besoin d’être restitué.
            </template>
            <template v-if="article.isExternalStock">
              Le stock de cet article est géré via un outil externe.
            </template>
          </div>
          <div class="text-gray-600" v-if="article.sites.length">
            Lieu{{article.sites.length > 1 ? 'x' : ''}} de distribution : {{article.sites.join(', ')}}
          </div>
        </div>
        <div class="md:flex-shrink-0 rounded-lg px-2 h-20 text-center leading-none font-semibold bg-indigo-100 text-indigo-500 justify-center items-center flex flex-col">
          <span
            class="mb-2 text-2xl"
            :class="isLowQuantity ? 'text-orange-600' : ''">
            {{!article.isExternalStock ? article.quantityAvailable : '…'}}
          </span>
          <span class="text-sm text-blue-600">Stock vestiaire</span>
        </div>
        <div class="md:ml-3 rounded-lg px-2 h-20 text-center leading-none font-semibold bg-indigo-100 text-indigo-500 justify-center items-center flex flex-col">
          <span class="mb-2 text-2xl">{{!article.isExternalStock ? article.quantityTotal : '…'}}</span>
          <span class="text-sm text-blue-600">Stock global</span>
        </div>
        <div class="md:ml-3 rounded-lg px-2 h-20 text-center leading-none font-semibold bg-indigo-100 text-indigo-500 justify-center items-center flex flex-col">
          <span class="mb-2 text-2xl">{{article.costInternal | formatFloat}} €</span>
          <span class="text-sm text-blue-600">Coût réel</span>
        </div>
        <div class="md:ml-3 rounded-lg px-2 h-20 text-center leading-none font-semibold bg-indigo-100 text-indigo-500 justify-center items-center flex flex-col">
          <span class="mb-2 text-2xl">{{article.costPublic | formatFloat}} €</span>
          <span class="text-sm text-blue-600">Coût affiché</span>
        </div>
      </div>
      <div v-if="$route.params.isInventoryAddSuccess" class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-4" role="alert">
        <strong class="font-bold mr-2">C’est tout bon !</strong>
        <span class="block sm:inline">L’ajout d’article a bien été pris en compte.</span>
      </div>
      <div v-if="$route.params.isInventoryRemoveSuccess" class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-4" role="alert">
        <strong class="font-bold mr-2">C’est tout bon !</strong>
        <span class="block sm:inline">La suppression d’articles a bien été prise en compte.</span>
      </div>
    </div>
    <article-operation-groups :article="article" :limit="10" />
    <article-employees :article="article" />
  </div>
</template>

<script>
import ArticleOperationGroups from '@components/ArticleOperationGroups'
import ArticleEmployees from '@components/ArticleEmployees'
import { capitalize } from '@utils/format'

export default {
  components: { ArticleOperationGroups, ArticleEmployees },
  data() {
    return {
      article: null
    }
  },
  methods: {
    capitalize
  },
  computed: {
    isLowQuantity() {
      return !this.article.isExternalStock && (this.article.quantityAvailable <= this.article.quantityLow)
    }
  },
  async created() {
    const { id } = this.$route.params
    try {
      this.article = await this.$store.dispatch('articles/getArticle', id)
    }
    catch (e) {
      this.$router.push({ name: 'stock' })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
