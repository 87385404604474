<template>
  <div class="mf-container mt-4" v-if="operationGroups.length || searchQuery">
    <div class="float-right">
      <input
        class="appearance-none border border-blue-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-300 text-left"
        type="text"
        placeholder="Rechercher un mouvement…"
        v-model="searchQuery"
        @input="loadOperations">
    </div>
    <div v-for="group in operationGroups" :key="group.month">
      <h2>{{group.month}}</h2>
      <operation-groups class="mb-5" :operationGroups="group.operationGroups" />
    </div>
    <p class="text-gray-600 my-2" v-if="!operationGroups.length">
      Aucun mouvement.
    </p>
    <div v-if="isLimited" class="text-gray-600">
      <hr class="mt-2 mb-4">
      Les {{limit}} derniers mouvements sont affichés.
      <button class="mf-secondary-button leading-normal ml-2" @click="loadMore">
        Afficher plus
      </button>
    </div>
  </div>
</template>

<script>
import OperationGroups from '@components/OperationGroups'
import { formatMonth } from '@utils/format'
import groupBy from 'lodash.groupby'

function groupOperationGroupsByMonth(operationGroups) {
  const groupedOperationGroups = groupBy(operationGroups, o => formatMonth(o.operationDate))
  return Object.keys(groupedOperationGroups).map(key => ({
    month: key,
    operationGroups: groupedOperationGroups[key]
  }))
}

export default {
  components: { OperationGroups },
  props: {
    article: Object,
    badge: Object,
    card: Object,
    phone: Object,
    limit: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      operationGroupsCount: 0,
      operationGroups: [],
      searchQuery: null,
      currentLimit: this.limit
    }
  },
  computed: {
    isLimited() {
      return !this.searchQuery && this.operationGroupsCount === this.currentLimit
    }
  },
  methods: {
    formatMonth,
    async loadMore() {
      this.currentLimit += this.limit
      this.loadOperations()
    },
    async loadOperations() {
      const query = this.searchQuery
      const limit = this.currentLimit
      const article = this.article && this.article.id
      const badge = this.badge && this.badge.id
      const card = this.card && this.card.id
      const phone = this.phone && this.phone.id
      const operationGroups = await this.$store.dispatch('operations/getOperationGroups', { query, limit, article, badge, card, phone })
      this.operationGroupsCount = operationGroups.length
      this.operationGroups = groupOperationGroupsByMonth(operationGroups)
    }
  },
  async created() {
    this.loadOperations()
  }
}
</script>

<style lang="scss" scoped>
</style>
