<template>
  <div>
    <div class="select-none flex items-center pb-1 cursor-pointer">
      <div
        class="bg-gray-100 rounded flex items-center p-1 px-2 pr-1 flex-grow hover:bg-gray-200"
        @click="increment">
        <div class="flex-grow leading-snug">
          <span class="pr-1">
            {{article.name}}
            <span class="text-gray-600 text-sm whitespace-no-wrap" v-if="isRestitution && !article.isReturnable">
              <br>Restitution optionnelle
            </span>
          </span>
          <span v-if="article.size" class="text-blue-500 text-sm pr-1">
            {{article.size}}
          </span>
          <span class="text-gray-600 text-sm whitespace-no-wrap">{{article.description}}</span>
        </div>
        <span class="text-gray-600 text-sm mr-2 whitespace-no-wrap" v-if="!article.isExternalStock && isRestitution">
          Possession : {{article.quantityAvailable}}
        </span>
        <span class="text-gray-600 text-sm mr-2 whitespace-no-wrap" v-if="!article.isExternalStock && !isRestitution">
          Stock : {{article.quantityAvailable}}
        </span>
        <div
          class="w-8 h-6 text-center font-bold rounded ml-1"
          :class="quantity > 0 ? 'bg-blue-200 text-blue-500' : 'text-gray-600'">
          {{quantity}}
        </div>
      </div>
      <button
        class="mf-secondary-button flex-shrink-0 leading-normal py-0 h-6 ml-2"
        :class="{invisible: quantity <= 0}"
        @click.prevent="decrement">
        Moins
      </button>
    </div>
    <div
      v-if="isCard && quantity && !isRestitution"
      class="select-none flex items-center pb-1">
      <label class="text-xs text-gray-600 uppercase font-semibold pr-2 pl-2">
        → Choisir une carte :
      </label>
      <div class="relative">
        <select
          v-model="card"
          @change="setCard(card)"
          class="appearance-none bg-gray-100 hover:bg-gray-200 border border-gray-100 text-gray-700 py-1 px-4 pr-6 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
          <option value="" disabled>Cartes disponibles</option>
          <option v-for="option in availableCards" :key="option.id" :value="option.id">{{option.identifier}}</option>
          <option v-if="!availableCards.length" disabled>Aucune carte disponible</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
        </div>
      </div>
    </div>
    <div
      v-if="isPhone && quantity && !isRestitution"
      class="select-none flex items-center pb-1">
      <label class="text-xs text-gray-600 uppercase font-semibold pr-2 pl-2">
        → Choisir un téléphone :
      </label>
      <div class="relative">
        <select
          v-model="phone"
          @change="setPhone(phone)"
          class="appearance-none bg-gray-100 hover:bg-gray-200 border border-gray-100 text-gray-700 py-1 px-4 pr-6 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
          <option value="" disabled>Téléphones disponibles</option>
          <option v-for="option in availablePhones" :key="option.id" :value="option.id">{{option.identifier}}</option>
          <option v-if="!availablePhones.length" disabled>Aucun téléphone disponible</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
        </div>
      </div>
    </div>
    <div
      v-if="isBadge && quantity && !isRestitution"
      class="select-none flex items-center pb-1">
      <label class="text-xs text-gray-600 uppercase font-semibold pr-2 pl-2">
        → Choisir un badge :
      </label>
      <div class="relative">
        <select
          v-model="badge"
          @change="setBadge(badge)"
          class="appearance-none bg-gray-100 hover:bg-gray-200 border border-gray-100 text-gray-700 py-1 px-4 pr-6 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
          <option value="" disabled>badges disponibles</option>
          <option v-for="option in filteredAvailableBadges" :key="option.id" :value="option.id">{{option.identifier}}</option>
          <option v-if="!filteredAvailableBadges.length" disabled>Aucun badge disponible</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isRestitution: Boolean,
    article: Object,
    availableCards: Array,
    availablePhones: Array,
    availableBadges: Array
  },
  data() {
    return {
      card: '',
      phone: '',
      badge: ''
    }
  },
  watch: {
    quantity() {
      if (!this.quantity) {
        if (this.isBadge) {
          this.setBadge(null)
          this.badge = ''
        }
        if (this.isCard) {
          this.setCard(null)
          this.card = ''
        }
        if (this.isPhone) {
          this.setPhone(null)
          this.phone = ''
        }
      }
    }
  },
  computed: {
    quantity() {
      return this.article.quantity || 0
    },
    isCard() {
      return this.article.name === 'Carte AS24'
    },
    isPhone() {
      return this.article.name === 'Téléphone'
    },
    isBadge() {
      return ['Badge Lille', 'Badge Harnes', 'Badge Vitrolles'].includes(this.article.name)
    },
    filteredAvailableBadges() {
      if (this.isBadge) {
        const site = this.article.name.split(' ')[1]
        return this.availableBadges.filter(badge => badge.site === site)
      }
      else {
        return []
      }
    }
  },
  methods: {
    setCard(cardId) {
      this.$emit('setCard', cardId)
    },
    setPhone(phoneId) {
      this.$emit('setPhone', phoneId)
    },
    setBadge(badgeId) {
      this.$emit('setBadge', badgeId)
    },
    increment() {
      if (this.quantity === 1 && (this.isCard || this.isPhone || this.isBadge)) {
        return
      }
      let id = this.article.id
      let quantity = this.quantity + 1
      this.$emit('change', { id, quantity })
    },
    decrement() {
      if (this.quantity - 1 >= 0) {
        let id = this.article.id
        let quantity = this.quantity - 1
        this.$emit('change', { id, quantity })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
