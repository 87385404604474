<template>
  <div class="text-gray-900 leading-normal antialiased">
    <div class="mf-no-print flex border-b border-gray-300 h-16 items-center bg-white overflow-hidden">
      <div class="container mx-auto px-4 flex items-center" >
        <router-link
          tag="h1"
          class="flex-shrink-0 text-center pb-0 text-lg text-blue-500 whitespace-no-wrap pr-6 cursor-pointer"
          :class="{'flex-grow': !$$isConnected}"
          to="/">
          <img src="~@images/favicon/apple-touch-icon.png" class="inline-block h-8 mr-1 -mt-1">
          Gestion des stocks
        </router-link>
        <!-- <div class="flex-1">
          <input class="bg-gray-200 appearance-none border-2 border-gray-200 rounded-lg w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-300 text-left" type="text" placeholder="Rechercher un salarié ou un article…">
        </di^v> -->
        <div class="flex flex-grow" v-if="$$isConnected">
          <!-- <router-link to="/" class="mf-header-link home-link">
            Accueil
          </router-link> -->
          <router-link to="/stock" class="mf-header-link">
            Stock
          </router-link>
          <router-link to="/salaries" class="mf-header-link">
            Salariés
          </router-link>
          <router-link to="/telephones" class="mf-header-link">
            Téléphones
          </router-link>
          <router-link to="/as24" class="mf-header-link">
            AS24
          </router-link>
          <router-link to="/badges" class="mf-header-link">
            Badges
          </router-link>
          <router-link to="/mouvements" class="mf-header-link">
            Mouvements
          </router-link>
        </div>
      </div>
    </div>
    <div class="mf-route-container container mx-auto px-4 pt-6 pb-8">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style scoped>
</style>
