<template>
  <div
    class="w-8 h-6 text-center font-bold rounded ml-1 bg-white"
    :class="isLowQuantity ? 'text-orange-600' : 'text-blue-500'">
    {{label}}
  </div>
</template>

<script>
export default {
  props: {
    article: Object,
    quantityKey: {
      type: String,
      default: 'quantityAvailable'
    },
    showLowQuantity: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isFlashing: false
    }
  },
  watch: {
    quantity(newQuantity, oldQuantity) {
      if (newQuantity !== oldQuantity) {
        this.$el.classList.add('mf-flash')
        setTimeout(() => {
          this.$el.classList.remove('mf-flash')
        }, 600)
      }
    }
  },
  computed: {
    isExternalStock() {
      return this.article.isExternalStock
    },
    isLowQuantity() {
      return this.showLowQuantity && !this.isExternalStock && (this.quantity <= this.article.quantityLow)
    },
    quantity() {
      return this.article[this.quantityKey]
    },
    label() {
      return this.isExternalStock ? '…' : this.quantity
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
