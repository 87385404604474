<template>
  <div v-if="employee">
    <div class="flex items-center pb-4">
      <h1 class="flex-grow pb-0">
        <router-link :to="{name: 'employees'}">Salariés</router-link>
        &nbsp;>&nbsp;
        {{employee.fullName}}
      </h1>
      <router-link
        v-if="employee && employee.operationGroups.length"
        tag="button"
        class="mf-secondary-button"
        :to="{name: 'restitution', params: {employeeId: employee && employee.id}}">
        Restituer du matériel
      </router-link>
      <router-link
        tag="button"
        class="mf-primary-button ml-3"
        :to="{name: 'delivery', params: {employeeId: employee && employee.id}}">
        Distribuer du matériel
      </router-link>
    </div>
    <employee :employee="employee" />
    <employee-operation-groups :employee="employee" />
    <employee-articles :employee="employee" />
  </div>
</template>

<script>
import Employee from '@components/Employee'
import EmployeeArticles from '@components/EmployeeArticles'
import EmployeeOperationGroups from '@components/EmployeeOperationGroups'

export default {
  components: { Employee, EmployeeArticles, EmployeeOperationGroups },
  data() {
    return {
      employee: null
    }
  },
  async created() {
    const { id } = this.$route.params
    try {
      this.employee = await this.$store.dispatch('employees/getEmployee', id)
    }
    catch (e) {
      this.$router.push({ name: 'employees' })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
