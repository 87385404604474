<template>
  <div v-if="article && badge">
    <div class="flex items-center pb-4">
      <h1 class="flex-grow pb-0">
        <router-link :to="{name: 'badges'}">Badges</router-link>
        &nbsp;>&nbsp;
        {{badge.identifier}}<br>
      </h1>
      <router-link
        tag="button"
        class="mf-primary-button ml-3"
        :to="{name: 'editBadge', params: {id: badge.id}}">
        Modifier
      </router-link>
    </div>
    <div class="mf-container">
      <div class="md:flex">
        <div class="mt-4 md:mt-0 flex-grow">
          <h2 class="pb-1 inline-block">
            Badge
            <span class="text-gray-500 ml-2">N° {{badge.identifier}}</span>
            <span v-if="badge.isLost" class="text-red-400 ml-2">PERDU / USÉ</span>
          </h2>
          <div class="text-gray-600">
            Site : {{badge.site}}
          </div>
        </div>
      </div>
    </div>
    <article-operation-groups :article="article" :badge="badge" />
    <article-employees :employees="employees" />
  </div>
</template>

<script>
import ArticleOperationGroups from '@components/ArticleOperationGroups'
import ArticleEmployees from '@components/ArticleEmployees'

export default {
  components: { ArticleOperationGroups, ArticleEmployees },
  data() {
    return {
      article: null,
      badge: null
    }
  },
  computed: {
    employees() {
      return this.badge.employee ? [this.badge.employee] : []
    }
  },
  async created() {
    const { id } = this.$route.params
    try {
      this.badge = await this.$store.dispatch('badges/getBadge', id)
      this.article = await this.$store.dispatch('articles/getArticleByName', 'Badge ' + this.badge.site)
    }
    catch (e) {
      this.$router.push({ name: 'stock' })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
