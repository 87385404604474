import Vue from '@vue'
import Router from 'vue-router'
import ArticleView from '@views/ArticleView'
import ArticleFormView from '@views/ArticleFormView'
import DashboardView from '@views/DashboardView'
import EmployeesView from '@views/EmployeesView'
import EmployeeView from '@views/EmployeeView'
import SheetView from '@views/SheetView'
import EmployeeFormView from '@views/EmployeeFormView'
import OperationsView from '@views/OperationsView'
import DeliveryFormView from '@views/DeliveryFormView'
import RestitutionFormView from '@views/RestitutionFormView'
import SigninView from '@views/SigninView'
import StockView from '@views/StockView'
import StockValuationView from '@views/StockValuationView'
import CardsView from '@views/CardsView'
import CardView from '@views/CardView'
import CardFormView from '@views/CardFormView'
import PhonesView from '@views/PhonesView'
import PhoneView from '@views/PhoneView'
import PhoneFormView from '@views/PhoneFormView'
import BadgesView from '@views/BadgesView'
import BadgeView from '@views/BadgeView'
import BadgeFormView from '@views/BadgeFormView'

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: DashboardView,
    redirect: '/stock'
  },
  {
    path: '/stock',
    component: {
      template: '<router-view></router-view>'
    },
    children: [
      {
        name: 'stock',
        path: '',
        component: StockView
      },
      {
        name: 'valuation',
        path: 'valorisation',
        component: StockValuationView
      },
      {
        name: 'createArticle',
        path: 'ajouter',
        component: ArticleFormView
      },
      {
        path: 'article/:id',
        component: {
          template: '<router-view></router-view>'
        },
        children: [
          {
            name: 'article',
            path: '',
            component: ArticleView
          },
          {
            name: 'editArticle',
            path: 'modifier',
            component: ArticleFormView
          }
        ]
      }
    ]
  },
  {
    path: '/mouvements',
    component: {
      template: '<router-view></router-view>'
    },
    children: [
      {
        name: 'operations',
        path: '',
        component: OperationsView
      },
      {
        name: 'delivery',
        path: 'distribuer',
        component: DeliveryFormView
      },
      {
        name: 'restitution',
        path: 'restituer',
        component: RestitutionFormView
      }
    ]
  },
  {
    path: '/salaries',
    component: {
      template: '<router-view></router-view>'
    },
    children: [
      {
        name: 'employees',
        path: '',
        component: EmployeesView
      },
      {
        name: 'createEmployee',
        path: 'ajouter',
        component: EmployeeFormView
      },
      {
        path: ':id',
        component: {
          template: '<router-view></router-view>'
        },
        children: [
          {
            name: 'employee',
            path: '',
            component: EmployeeView
          },
          {
            name: 'editEmployee',
            path: 'modifier',
            component: EmployeeFormView
          },
          {
            name: 'print',
            path: 'fiche',
            component: SheetView
          }
        ]
      }
    ]
  },
  {
    path: '/as24',
    component: {
      template: '<router-view></router-view>'
    },
    children: [
      {
        name: 'cards',
        path: '',
        component: CardsView
      },
      {
        name: 'createCard',
        path: 'ajouter',
        component: CardFormView
      },
      {
        path: ':id',
        component: {
          template: '<router-view></router-view>'
        },
        children: [
          {
            path: '',
            name: 'card',
            component: CardView
          },
          {
            name: 'editCard',
            path: 'modifier',
            component: CardFormView
          }
        ]
      }
    ]
  },
  {
    path: '/telephones',
    component: {
      template: '<router-view></router-view>'
    },
    children: [
      {
        name: 'phones',
        path: '',
        component: PhonesView
      },
      {
        name: 'createPhone',
        path: 'ajouter',
        component: PhoneFormView
      },
      {
        path: ':id',
        component: {
          template: '<router-view></router-view>'
        },
        children: [
          {
            path: '',
            name: 'phone',
            component: PhoneView
          },
          {
            name: 'editPhone',
            path: 'modifier',
            component: PhoneFormView
          }
        ]
      }
    ]
  },
  {
    path: '/badges',
    component: {
      template: '<router-view></router-view>'
    },
    children: [
      {
        name: 'badges',
        path: '',
        component: BadgesView
      },
      {
        name: 'createBadge',
        path: 'ajouter',
        component: BadgeFormView
      },
      {
        path: ':id',
        component: {
          template: '<router-view></router-view>'
        },
        children: [
          {
            path: '',
            name: 'badge',
            component: BadgeView
          },
          {
            name: 'editBadge',
            path: 'modifier',
            component: BadgeFormView
          }
        ]
      }
    ]
  },
  {
    path: '/connexion',
    name: 'signin',
    component: SigninView
  }
]

export default new Router({
  mode: 'history',
  routes
})
