import './stylesheets.scss'
import Vue from '@vue'
import router from './router'
import App from '@views/App.vue'
import store from '@/store'

import '@/mixins/mixins'

const PUBLIC_ROUTES = ['signin']
router.beforeEach((to, from, next) => {
  if (PUBLIC_ROUTES.includes(to.name)) {
    next()
  }
  else {
    store.dispatch('account/getAccount')
      .then(isConnected => {
        return isConnected ? next() : next({ name: 'signin' })
      })
  }
})

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
