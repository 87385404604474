<template>
  <div>
    <table class="table-fixed w-full mb-3" v-if="filteredBadges && filteredBadges.length">
      <thead>
        <tr>
          <th>Numéro</th>
          <th>Site</th>
          <th class="w-1/2">Salarié</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="badge in filteredBadges" :key="badge.id"
          class="odd:bg-gray-100 hover:bg-indigo-100"
          :class="{
            'cursor-pointer': !isNested,
            'mf-highlighted': $route.params.badgeId === badge.id,
            'mf-lost': badge.isLost
          }"
          @click="$router.push({name: 'badge', params: {id: badge.id}})">
          <td class="mf-tnum border px-4 py-1 whitespace-no-wrap">
            {{badge.identifier}}
          </td>
          <td class="border px-4 py-1 whitespace-no-wrap">
            {{badge.site}}
          </td>
          <td class="border px-4 py-1 whitespace-no-wrap">
            {{badge.employee ? badge.employee.fullName : '…'}}
          </td>
        </tr>
      </tbody>
    </table>
    <p class="text-gray-600" v-else>
      Aucun badge.
    </p>
  </div>
</template>

<script>
export default {
  props: {
    isNested: Boolean,
    badges: Array
  },
  computed: {
    filteredBadges() {
      return this.badges
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
