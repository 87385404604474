import api from '@/store/api'
import { prepareArticles } from '@utils/operations'
import { getSizesFromOperationGroups, getStockFromOperationGroups } from '@utils/display'
import { buildQuery } from '@utils/format'

const actions = {
  getEmployees(_context, params) {
    const query = buildQuery(params)
    return api.get('/employees' + query)
      .then(({ data }) => {
        return data
      })
  },
  getEmployee(_context, id) {
    return api.get('/employees/' + id)
      .then(({ data }) => {
        return data
      })
  },
  getEmployeeSizes(context, id) {
    return context.dispatch('getEmployee', id).then(employee => {
      return getSizesFromOperationGroups(employee.operationGroups)
    })
  },
  getEmployeeArticles(context, id) {
    return context.dispatch('getEmployee', id).then(employee => {
      return prepareArticles(getStockFromOperationGroups(employee.operationGroups)).reduce((memo, article) => {
        // Clone special articles
        if (article.badges && article.badges.length) {
          article.badges.forEach(badge => {
            memo.push({
              ...article,
              id: [article.id, badge.id].join('|'),
              badge,
              description: badge.identifier,
              quantity: 1,
              quantityAvailable: 1
            })
          })
        }
        else if (article.cards && article.cards.length) {
          article.cards.forEach(card => {
            memo.push({
              ...article,
              id: [article.id, card.id].join('|'),
              card,
              description: card.identifier,
              quantity: 1,
              quantityAvailable: 1
            })
          })
        }
        else if (article.phones && article.phones.length) {
          article.phones.forEach(phone => {
            memo.push({
              ...article,
              id: [article.id, phone.id].join('|'),
              phone,
              description: phone.identifier,
              quantity: 1,
              quantityAvailable: 1
            })
          })
        }
        else {
          if (article.isReturnable) {
            article.quantity = article.quantityAvailable
          }
          memo.push(article)
        }
        return memo
      }, [])
    })
  },
  createEmployee(_context, employee) {
    return api.post('/employees', employee)
      .then(({ data }) => {
        return data
      })
  },
  updateEmployee(_context, employee) {
    return api.put('/employees/' + employee.id, employee)
      .then(({ data }) => {
        return data
      })
  },
  removeEmployee(_context, employee) {
    return api.delete('/employees/' + employee.id)
      .then(({ data }) => {
        return data
      })
  }
}

export default {
  namespaced: true,
  actions
}
