import Vue from '@vue'

Vue.mixin({
  computed: {
    $$isConnected() {
      return this.$store.getters['account/isConnected']
    }
  },
  methods: {
    beta(event) {
      event.preventDefault()
      alert('Cette fonctionnalité n’est pas encore disponible dans la version Beta.')
      return false
    }
  }
})
