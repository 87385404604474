import format from 'date-fns/format'
import { fr } from 'date-fns/locale'
import subDays from 'date-fns/subDays'
import isAfter from 'date-fns/isAfter'
import sortBy from 'lodash.sortby'

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function pluralize(string, count) {
  return string + (count > 1 ? 's' : '')
}

export function formatOperationType(type) {
  switch (type) {
    case 'delivery': return 'Remise'
    case 'restitution': return 'Restitution'
    case 'inventory_add': return 'Inventaire : Ajout'
    case 'inventory_remove': return 'Inventaire : Suppression'
    case 'lost': return 'Perte'
    default: return 'Remise'
  }
}

export function formatOperationArticles(operationGroup, concise) {
  let articles = sortBy(operationGroup.operations, o => o.article.name).map(o => {
    const sizeLabel = o.article.size ? ' ' + o.article.size : ''
    return [Math.abs(o.quantityDiff), o.article.name + sizeLabel].join(' ')
  })
  if (concise && articles.length > 4) {
    articles = articles.slice(0, 4)
    articles.push('…')
  }
  return articles.join(', ')
}

export function formatMonth(date) {
  return capitalize(format(new Date(date), 'MMMM yyyy', { locale: fr }))
}

export function formatDate(date, formatting = 'd MMM yy') {
  return format(new Date(date), formatting, { locale: fr })
}

export function formatToday(formatting = 'yyyy-MM-dd') {
  return format(new Date(), formatting, { locale: fr })
}

export function isAfterDays(date, days) {
  return isAfter(new Date(date), subDays(new Date(), days))
}

export function buildQuery(params) {
  params = params || {}
  Object.keys(params)
    .forEach((key) => {
      if (!params[key]) {
        delete params[key]
      }
      else if (params[key].trim) {
        params[key] = params[key].trim()
      }
    })
  return Object.values(params).length ? '?' + new URLSearchParams(params) : ''
}
