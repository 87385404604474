import api from '@/store/api'
import { buildQuery } from '@utils/format'

const actions = {
  getOperationGroups(_context, params) {
    const query = buildQuery(params)
    return api.get('/operation_groups' + query)
      .then(({ data }) => {
        return data
      })
  },
  createOperationGroup(context, operationGroup) {
    return api.post('/operation_groups', { operationGroup })
      .then(({ data }) => {
        return data
      })
  },
  deleteOperationGroup(context, { id }) {
    return api.delete('/operation_groups/' + id)
      .then(({ data }) => {
        return data
      })
  }
}

export default {
  namespaced: true,
  actions
}
