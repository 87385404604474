<template>
  <div>
    <div v-for="(article, i) in articles" :key="article.id">
      <label
        v-if="i == 0 || article.category !== articles[i - 1].category"
        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        :class="{'pt-6': i !== 0}">
        {{article.category}}
      </label>
      <article-picker
        :isRestitution="isRestitution"
        :article="article"
        :availableCards="availableCards"
        :availablePhones="availablePhones"
        :availableBadges="availableBadges"
        @setCard="setCard"
        @setPhone="setPhone"
        @setBadge="setBadge"
        @change="change" />
    </div>
  </div>
</template>

<script>
import ArticlePicker from '@components/ArticlePicker'

export default {
  components: { ArticlePicker },
  props: {
    isRestitution: Boolean,
    articles: Array,
    availableCards: Array,
    availablePhones: Array,
    availableBadges: Array
  },
  methods: {
    change(params) {
      this.$emit('change', params)
    },
    setCard(params) {
      this.$emit('setCard', params)
    },
    setPhone(params) {
      this.$emit('setPhone', params)
    },
    setBadge(params) {
      this.$emit('setBadge', params)
    }
  }
}
</script>

<style lang="scss" scoped>
.mf-sizes-columns {
  column-count: 2;
  column-gap: 0;

  * {
    break-inside: avoid;
  }
}
</style>
